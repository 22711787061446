// import { FaQuoteLeft } from "react-icons/fa";
// import { Button } from "../ui/button";
// import { useNavigate } from "react-router-dom";

// export const Welcome = () => {
//     const navigate = useNavigate()

//     return (
//         <div className="container mt-20">
//             <div className="grid lg:grid-cols-2 lg:gap-x-[100px]">
//                 <div>
//                     <img src="/office1.jpeg" alt="office image" className="w-full h-auto object-cover" />
//                 </div>
//                 <div>
//                     <div className="text-3xl lg:text-4xl mt-3 lg:mt-0 tracking-tight font-[500]">Welcome to <span className="text-[#C7A15E]">Frontline</span></div>
//                     <p className="mt-7 lg:max-w-[500px]">
//                         Frontline is a premium garment fabric studio that offers high-quality fabrics for clothing brands and fashion designers.
//                     </p>
//                     <p className="mt-7 lg:max-w-[500px]">
//                         Our company is committed to providing exceptional service and products to our clients. We specialize in a wide range of fabrics, including cotton, silk, linen, wool, and more.
//                     </p>
//                     <div className="mt-7 flex">
//                         <div className="flex gap-x-2 lg:gap-x-3 text-sm lg:text-2xl font-[500] bg-[#C7A15E]/10 rounded-full px-5 py-3">
//                             <FaQuoteLeft className="text-[#C7A15E]" /> For Fabric that Shines, Think FrontLine.
//                         </div>
//                     </div>
//                     <Button
//                         variant="customoutline"
//                         className="mt-10 font-[500]"
//                         onClick={() => navigate("/contact-us")} 
//                     >
//                         Know more
//                     </Button>
//                 </div>
//             </div>
//         </div>
//     );
// };



import { FaQuoteLeft } from "react-icons/fa";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const Welcome = () => {
    const navigate = useNavigate();
    const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.2 });

    const fadeInVariant = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
    };

    return (
        <motion.div ref={ref} className="container mt-20">
            <motion.div 
                className="grid lg:grid-cols-2 lg:gap-x-[100px]"
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={fadeInVariant}
            >
                {/* Image Section */}
                <motion.div
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={fadeInVariant}
                    className="overflow-hidden"
                >
                    <motion.img
                        src="/factory01.png"
                        alt="office image"
                        className="w-full  h-[450px] object-cover"
                        whileHover={{ scale: 1.05 }}
                    />
                </motion.div>

                {/* Text Section */}
                <motion.div initial="hidden" animate={inView ? "visible" : "hidden"} variants={fadeInVariant}>
                    <div className="text-3xl lg:text-4xl mt-3 lg:mt-0 tracking-tight font-[500]">
                    {/* #C7A15E */}
                        Welcome to <span className="text-[#f04b4c]">Frontline</span>
                    </div>
                    <p className="mt-7 lg:max-w-[500px]">
                        Frontline is a premium garment fabric studio that offers high-quality fabrics for clothing brands and fashion designers.
                    </p>
                    <p className="mt-7 lg:max-w-[500px]">
                        Our company is committed to providing exceptional service and products to our clients. We specialize in a wide range of fabrics, including cotton, silk, linen, wool, and more.
                    </p>
                    
                    {/* Quote Section */}
                    <motion.div 
                        className="mt-7 flex"
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={fadeInVariant}
                    >
                        <div className="flex gap-x-2 lg:gap-x-3 text-sm lg:text-2xl font-[500] bg-[#C7A15E]/10 rounded-full px-5 py-3">
                            <FaQuoteLeft className="text-[#f04b4c]" /> For Fabric that Shines, Think FrontLine.
                        </div>
                    </motion.div>

                    {/* Button */}
                    <motion.div 
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={fadeInVariant}
                    >
                        <Button
                            variant="customoutline"
                            className="mt-10 font-[500]"
                            onClick={() => navigate("/contact-us")}
                        >
                            Know more
                        </Button>
                    </motion.div>
                </motion.div>
            </motion.div>
        </motion.div>
    );
};
