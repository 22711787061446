import { useEffect } from "react";
import { HomeAbout } from "../components/home/about";
import { Commitment } from "../components/home/commitment";
import { Hero } from "../components/home/hero";
import { HomeServices } from "../components/home/services";
import { Trust } from "../components/home/trust";
import { WhyChooseUs } from "../components/home/why-choose-us";
import { PremiumProducts } from "../components/home/premium-products";
import ClientReviewsCarousel from "../components/home/review";
import ThinkVSMSection from "../components/home/thinkfrontline";
import SustainabilitySection from "../components/home/sustainibilityhome";
import WhyVSM from "../components/home/whtfrontline";
import ProductSection from "../components/home/highqualitybyfrontline";
import QualityAssurance from "../components/home/qualityassurance";
import PartnerLogos from "../components/home/PartnerLogos";
import Technology from "../components/home/Technology";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <Hero />
            <ThinkVSMSection />
            {/* <HomeAbout /> */}
            <WhyChooseUs />
            <Trust />
            <WhyVSM />
            {/* <HomeServices /> */}
            <QualityAssurance />
            <SustainabilitySection />
            {/* <Commitment /> */}
            <ProductSection />
            <PartnerLogos />
            <Technology />
            {/* <PremiumProducts /> */}
            {/* <ClientReviewsCarousel /> */}

        </>
    );
};

export default Home;