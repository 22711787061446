// import { CircleCheckBig } from "lucide-react";

// export const ContactHero = () => {
//     return (
//         <div className="container mt-20">
//             <div className="grid lg:grid-cols-2">
//                 <div className="">
//                     <img src="/office1.jpeg" alt="office image" className="w-full h-[450px]" />
//                 </div>
//                 <div className="flex justify-center items-center">
//                     <div className="lg:bg-white lg:ms-[-200px] lg:h-[400px] flex flex-col justify-center lg:shadow-xl lg:p-5">
//                         <div className="text-3xl lg:text-4xl mt-5 lg:mt-0 tracking-tight font-[500]">Get in <span className="text-[#C7A15E]">Touch</span></div>
//                         <div className="mt-5 grid lg:grid-cols-2 gap-7 lg:p-5">
//                             <div>
//                                 <div className="flex items-center gap-x-2">
//                                     <CircleCheckBig className="text-[#C7A15E]" />
//                                     <div className="text-2xl font-[500] tracking-tight">Pioneering Excellence</div>
//                                 </div>
//                                 <p className="lg:max-w-[250px] mt-2 font-[500] text-black/70">
//                                     Frontline Textiles drives innovation with unmatched craftsmanship and quality.
//                                 </p>
//                             </div>
//                             <div>
//                                 <div className="flex items-center gap-x-2">
//                                     <CircleCheckBig className="text-[#C7A15E]" />
//                                     <div className="text-2xl font-[500] tracking-tight">Wide Range of Fabrics</div>
//                                 </div>
//                                 <p className="lg:max-w-[250px] mt-2 font-[500] text-black/70">
//                                     From satin to viscose, we offer premium fabrics crafted for every need.
//                                 </p>
//                             </div>
//                             <div>
//                                 <div className="flex items-center gap-x-2">
//                                     <CircleCheckBig className="text-[#C7A15E]" />
//                                     <div className="text-2xl font-[500] tracking-tight">Global Reach</div>
//                                 </div>
//                                 <p className="lg:max-w-[250px] mt-2 font-[500] text-black/70">
//                                     Experience seamless worldwide shipping with dedicated customer support.
//                                 </p>
//                             </div>
//                             <div>
//                                 <div className="flex items-center gap-x-2">
//                                     <CircleCheckBig className="text-[#C7A15E]" />
//                                     <div className="text-2xl font-[500] tracking-tight">Home Furnishing</div>
//                                 </div>
//                                 <p className="lg:max-w-[250px] mt-2 font-[500] text-black/70">
//                                     Durable, elegant fabrics to refresh interiors with curtains and upholstery.
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };



"use client";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { CircleCheckBig } from "lucide-react";

export const ContactHero = () => {
    const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.2 });

    return (
        <div ref={ref} className="container mt-20">
            <div className="grid lg:grid-cols-2">
                {/* Image Section */}
                <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
                    transition={{ duration: 0.7, ease: "easeOut" }}
                >
                    <img src="/office1.jpeg" alt="office image" className="w-full h-[450px]" />
                </motion.div>

                {/* Content Section */}
                <motion.div
                    className="flex justify-center items-center"
                    initial={{ opacity: 0, x: 50 }}
                    animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }}
                    transition={{ duration: 0.7, ease: "easeOut", delay: 0.2 }}
                >
                    <div className="lg:bg-white lg:ms-[-200px] lg:h-[400px] flex flex-col justify-center lg:shadow-xl lg:p-5">
                        {/* Heading */}
                        <motion.div
                            className="text-3xl lg:text-4xl mt-5 lg:mt-0 tracking-tight font-[500]"
                            initial={{ opacity: 0, y: 30 }}
                            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
                            transition={{ duration: 0.6, ease: "easeOut", delay: 0.3 }}
                        >
                            {/* #C7A15E */}
                            Get in <span className="text-[#f04b4c]">Touch</span>
                        </motion.div>

                        {/* Feature Grid */}
                        <div className="mt-5 grid lg:grid-cols-2 gap-7 lg:p-5">
                            {[
                                {
                                    title: "Pioneering Excellence",
                                    text: "Frontline Textiles drives innovation with unmatched craftsmanship and quality.",
                                },
                                {
                                    title: "Wide Range of Fabrics",
                                    text: "From satin to viscose, we offer premium fabrics crafted for every need.",
                                },
                                {
                                    title: "Global Reach",
                                    text: "Experience seamless worldwide shipping with dedicated customer support.",
                                },
                                {
                                    title: "Home Furnishing",
                                    text: "Durable, elegant fabrics to refresh interiors with curtains and upholstery.",
                                },
                            ].map((item, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                                    transition={{ duration: 0.5, ease: "easeOut", delay: index * 0.2 + 0.4 }}
                                >
                                    <div className="flex items-center gap-x-2">
                                        <CircleCheckBig className="text-[#f04b4c]" />
                                        <div className="text-2xl font-[500] tracking-tight">{item.title}</div>
                                    </div>
                                    <p className="lg:max-w-[250px] mt-2 font-[500] text-black/70">
                                        {item.text}
                                    </p>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};
