// export const Team = () => {
//     return (
//         <div className="container pt-20 text-center">
//             <h1 className="text-3xl lg:text-4xl">The Team</h1>
//             <p className="lg:max-w-[800px] mx-auto">
//                 At Frontline Textiles, we pride ourselves on having a team of over 25 dedicated and highly skilled professionals based at our Surat headquarters. Our team includes designers, project managers, operations experts, and sales and marketing specialists, all united by a shared passion for fabric design, an unwavering commitment to quality, and a relentless focus on customer satisfaction.
//             </p>
//             <p className="lg:max-w-[800px] mx-auto mt-2">
//                 Each project at Frontline Textiles is entrusted to a dedicated team that works hand-in-hand with our clients from the initial concept to the final delivery. This ensures a personalized, collaborative experience that upholds the highest standards of craftsmanship and client service at every step of the journey.
//             </p>
//         </div>
//     );
// };



import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const Team = () => {
    const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.2 });

    const fadeInVariant = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
    };

    return (
        <motion.div ref={ref} className="container pt-20 text-center">
            {/* Heading Animation */}
            <motion.h1
                className="text-3xl lg:text-4xl text-[#f04b4c]"
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={fadeInVariant}
            >
                The Team
            </motion.h1>

            {/* First Paragraph Animation */}
            <motion.p
                className="lg:max-w-[800px] mx-auto mt-5"
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={fadeInVariant}
            >
                At Frontline Textiles, we pride ourselves on having a team of over 25 dedicated and highly skilled professionals based at our Surat headquarters. Our team includes designers, project managers, operations experts, and sales and marketing specialists, all united by a shared passion for fabric design, an unwavering commitment to quality, and a relentless focus on customer satisfaction.
            </motion.p>

            {/* Second Paragraph Animation */}
            <motion.p
                className="lg:max-w-[800px] mx-auto mt-5"
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={fadeInVariant}
            >
                Each project at Frontline Textiles is entrusted to a dedicated team that works hand-in-hand with our clients from the initial concept to the final delivery. This ensures a personalized, collaborative experience that upholds the highest standards of craftsmanship and client service at every step of the journey.
            </motion.p>
        </motion.div>
    );
};
