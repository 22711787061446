export default function ContactSection() {
    return (
        <div className="w-full bg-white">
            <div className="container mx-auto px-4 py-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {/* <div className="text-center">
                    <h2 className="font-semibold text-xl mb-2">PHONE</h2>
                    <p className="text-gray-600">+91 63728 29190</p>
                </div> */}
                <div className="text-center">
                    <h2 className="font-semibold text-xl mb-2">Phone / Whatsapp / Viber:</h2>
                    <p className="text-gray-600">+91 78782 51565</p>
                </div>
                <div className="text-center">
                    <h2 className="font-semibold text-xl mb-2">MAIL</h2>
                    <p className="text-gray-600">info@frontlinetextiles.com</p>
                </div>
                <div className="text-center">
                    <h2 className="font-semibold text-xl mb-2">Address</h2>
                    <p className="text-gray-600">Plot No. 39 To 49, Vibhag-8/ Plot No. 13 to 16 & 60 to 63, Vibhag-19, Om Textile Park, Umbhel,Pared road, Kamrej, Surat, Gujarat, 394180</p>
                </div>
            </div>
        </div>
    )
}