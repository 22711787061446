import { Button } from "../ui/button";
import { FaWhatsapp } from "react-icons/fa";
import { IoIosContact } from "react-icons/io";
import { navigateToEmail } from "../headline";
import { Mail } from "lucide-react";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

export const WhyChooseUs = () => {
  const phoneNumber = "+917878251565";

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="relative h-auto min-h-[70vh] mt-20 bg-fixed bg-center bg-cover flex items-center justify-center px-4 sm:px-6 md:px-8 lg:px-10 py-20">
      {/* Overlay */}
      <div className="absolute inset-0 bg-gray-50 opacity-60"></div>

      <motion.div
        initial={{ opacity: 0, y: 150 }}
        animate={isVisible ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1.5 }}
        className="relative z-10 w-full max-w-[1000px] text-center"
      >
        {/* Content */}
        <div className="text-2xl sm:text-3xl md:text-4xl font-semibold mb-4 leading-tight">
          Unique <span className="text-[#f04b4c]">Fabrics</span> For Unique
          Requirements
        </div>
        <p className="text-sm sm:text-base md:text-lg leading-relaxed">
          Frontline Textiles is a distinguished global provider of exclusive,
          bespoke fabric solutions, specializing in the art of luxury
          handcrafted textiles. Since our establishment in 2012, we have
          curated over 1600 tailor-made fabric creations, with more than
          10,000 meters of exquisite materials currently in production. Our
          meticulously crafted fabrics have graced the most opulent interiors
          and high-profile projects, offering unparalleled quality and elegance
          to clients across the world. At Frontline Textiles, we redefine
          sophistication, ensuring that each piece embodies the finest
          standards of craftsmanship and style.
        </p>

        <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 mt-6 justify-center items-center">
          <Button
            variant="customoutline"
            className="w-full sm:w-auto font-[500] flex items-center gap-x-2 px-4 py-2"
            onClick={() => window.open(`tel:${phoneNumber}`, "_blank")}
          >
            <IoIosContact size={20} /> Contact Us
          </Button>

          <Button
            variant="customoutline"
            className="w-full sm:w-auto font-[500] flex items-center gap-x-2 px-4 py-2"
            onClick={() =>
              window.open(`https://wa.me/${phoneNumber}`, "_blank")
            }
          >
            <FaWhatsapp size={20} /> WhatsApp
          </Button>

          <Button
            onClick={navigateToEmail}
            variant="customoutline"
            className="w-full sm:w-auto font-[500] flex items-center gap-x-2 px-4 py-2"
          >
            <Mail size={20} /> Email Us
          </Button>
        </div>
      </motion.div>
    </div>
  );
};
