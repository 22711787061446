import { useEffect } from "react";
import { motion } from "framer-motion";
import Fivesaudit from "./fivesaudit";

const Sustainability = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const steps = [
    {
      id: 1,
      title: "Eco Friendly Fibres",
      description:
        "Our company is committed to integrating sustainable practices into every aspect of our operations...",
      imgSrc:
        "https://cdn.prod.website-files.com/6458e835fee2dd2a405bf1de/6458e835fee2dd6fff5bf2ff_Icon%20(7).svg",
    },
    {
      id: 2,
      title: "Smart Technology Yarns",
      description:
        "We also use state-of-the-art technology in our spinning process to create sustainable yarns...",
      imgSrc:
        "https://cdn.prod.website-files.com/6458e835fee2dd2a405bf1de/6458e835fee2ddb8125bf301_Icon%20(8).svg",
    },
    {
      id: 3,
      title: "Seamless Integration",
      description:
        "Sustainability is at the forefront of our operations and is seamlessly integrated into every step...",
      imgSrc:
        "https://cdn.prod.website-files.com/6458e835fee2dd2a405bf1de/6458e835fee2dd01ae5bf2fe_Icon%20(9).svg",
    },
    {
      id: 4,
      title: "Renewable Energy",
      description:
        "Our spinning system is powered by 85% renewable energy sources, including wind and solar power...",
      imgSrc:
        "https://cdn.prod.website-files.com/6458e835fee2dd2a405bf1de/6458e835fee2dd68b35bf2fd_Asset%202.svg",
    },
  ];

  return (
    <div>
      <motion.div className="relative w-full">
        {/* Image Animation */}
        <motion.img
          src="/sustainiblity01.jpg"
          alt="Sustainability Banner"
          className="w-full h-[60vh] sm:h-[70vh] md:h-[calc(80vh-90px)] object-cover brightness-50"
          initial={{ opacity: 0, scale: 1.1 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        />

        {/* Text Animation */}
        <motion.div
          className="absolute inset-0 flex items-center justify-center text-white text-center px-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.8, ease: "easeOut" }}
        >
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold">
            <span className="text-[#f04b4c]">Sustainability</span> - Seamless
            Integration
          </h1>
        </motion.div>
      </motion.div>
      ;
      <div>
        <div className="container">
          <motion.div
            className="mt-8 mb-15"
            initial={{ opacity: 0, y: 50 }} // Start from below with opacity 0
            animate={{ opacity: 1, y: 0 }} // Move up to original position with opacity 1
            transition={{ duration: 0.8, ease: "easeOut" }} // Smooth transition
          >
            <h1 className="text-3xl font-semibold">
              Certifications & Sustainability
            </h1>
            <p className="text-lg mt-1">
              We understand that sustainability is essential for the future of
              our planet, and we take this responsibility seriously. By choosing
              our products, you can feel confident that you're choosing a
              company that values sustainability and environmental
              responsibility.
            </p>
          </motion.div>
        </div>

        <div className="container relative flex flex-col items-center">
          {/* Vertical Line */}
          <div
            className="absolute linessus left-2/1 -translate-x-1/2 h-full w-[2px] bg-green-300"
            style={{
              top: "150px",
              bottom: "150px",
              height: "calc(100% - 230px)",
            }}
          ></div>

          {/* Steps */}
          {steps.map((item, index) => (
            <motion.div
              key={item.id}
              className={`relative flex items-center ${
                index % 2 === 0 ? "justify-start" : "justify-end"
              } w-full mt-10`}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
              viewport={{ amount: 0.5 }} // Jab 50% viewport me aayega tab trigger hoga
            >
              {/* Numbering Circle */}
              <div className="absolute linessus left-1/2 -translate-x-1/2 z-10 flex items-center justify-center w-10 h-11 text-white text-xl font-semibold bg-green-400 rounded-full">
                {item.id}
              </div>

              {/* Horizontal Line */}
              <div
                className={`absolute linessus left-1/2 -translate-x-2/1 w-[11rem] h-[2px] bg-green-300 ${
                  index % 2 === 0 ? "-translate-x-full" : ""
                }`}
              ></div>

              {/* Content */}
              <motion.div
                className="lg:max-w-[500px] p-5 bg-green-50 z-30 rounded-xl"
                initial={{ opacity: 0, x: index % 2 === 0 ? -100 : 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                viewport={{ amount: 0.5 }} // Jab bhi viewport me aayega tab animation chalega
              >
                <img src={item.imgSrc} alt="" className="pb-3" />
                <h4 className="text-xl font-semibold">{item.title}</h4>
                <p className="mt-1">{item.description}</p>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
      <div className="mt-20">
        <Fivesaudit />
      </div>
    </div>
  );
};

export default Sustainability;
