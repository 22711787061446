import { Sheet, SheetClose, SheetContent, SheetTrigger } from "./ui/sheet";
import { RiMenu3Fill } from "react-icons/ri";
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from "../lists/route-list";
import { Link, MessageSquareMore } from 'lucide-react';
import { CollectionDropdown } from "./CollectionDropdown";
// import Home from "../pages/home";
// import { HomeAbout } from "./home/about";

export const Navbar = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    // bg-[#525083] 
    return (
        <div className="h-[60px] md:h-[50px] w-full z-50">
            <div className="container h-full flex justify-between md:justify-center items-center">
                <div className="flex md:hidden">
                    <div onClick={() => navigate("/")}>
                        <img src="/logo.png" className="h-[150px]" alt="logo" />
                    </div>
                </div>
                <div className="hidden lg:flex gap-x-8 text-lg capitalize">
                    {routes.map((route, index) => (
                        route.name.toLowerCase() === 'collection' ? (
                            <CollectionDropdown key={index} />
                        ) : (  
                            <div
                                onClick={() => navigate(route.href)}
                                // text-blue-900
                                key={index}
                                className={`cursor-pointer hover:text-[#f04b4c] font-[500] text-base ${pathname === route.href && 'text-[#f04b4c]'}`}
                            >
                                {route.name}
                            </div>
                        )
                    ))}
                </div>
                <Sheet>
                    <SheetTrigger className="lg:hidden">
                        <RiMenu3Fill size={24} />
                    </SheetTrigger>
                    <SheetContent className="flex flex-col justify-between" side="left">
                        <div className="mt-14">
                            {routes.map((route, index) => (
                                <SheetClose key={index} className="w-full text-start">
                                    <div
                                        onClick={() => navigate(route.href)}
                                        className={`cursor-pointer hover:text-[#f04b4c] py-4 border-b ${pathname === route.href && 'text-[#f04b4c]'}`}
                                    >
                                        {route.name}
                                    </div>
                                </SheetClose>
                            ))}
                        </div>
                        <div className="flex items-center gap-x-2">
                            <MessageSquareMore size={18} className="text-[#86601e]" />
                            (+91) 78782 51565
                        </div>
                    </SheetContent>
                </Sheet>
            </div>
        </div>
    );
};