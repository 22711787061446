export const routes = [
    {
        name: "Collection",
        href: "/collection"
    },
    // {
    //     name: "Services",
    //     href: "/service"
    // },
    {
        name: "Sustainability",
        href: "/sustainability"
    },
    {
        name: "Quality",
        href: "/quality"
    },
    {
        name: "Contact Us",
        href: "/contact-us"
    },
    {
        name: "About Us",
        href: "/company"
    },
]