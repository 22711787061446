import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const FadeInWhenVisible = ({ children }) => {
  const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.2 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.6 }}
    >
      {children}
    </motion.div>
  );
};

const Quality = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* Hero Section */}
      <div className="relative h-[80vh] w-full">
        <motion.img
          src="/mm1.jpg"
          alt="Quality Hero Image"
          className="h-full w-full object-cover"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
        <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center">
          <motion.h1
            className="text-4xl sm:text-5xl font-semibold text-white text-center"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <span className="text-[#f04b4c]">Quality</span> of Our Products
          </motion.h1>
        </div>
      </div>

      <div className="max-w-6xl mx-auto px-6 py-12">
        <FadeInWhenVisible>
          <section className="text-center">
            <h2 className="text-3xl sm:text-4xl font-semibold text-gray-700">
              Our <span className="text-[#f04b4c]">Commitment</span> to
              Excellence
            </h2>
            <p className="mt-4 text-gray-600 leading-relaxed">
              All products at Frontline are uniquely batch-identified and
              undergo rigorous inspections at every stage of the manufacturing
              process using cutting-edge technology. Our in-house and certified
              external laboratories ensure that every product meets the highest
              standards.
            </p>
          </section>
        </FadeInWhenVisible>

        <FadeInWhenVisible>
          <section className="grid lg:grid-cols-2 gap-12 items-center mt-12">
            <div className="space-y-6">
              <h3 className="text-2xl font-medium text-gray-700">
                <span className="text-[#f04b4c]">Quality</span> Assurance
              </h3>
              <p className="text-gray-600 leading-relaxed">
                Frontline adopts advanced methodologies, including Kaizen and
                Six Sigma, ensuring continuous improvement. Our facility is
                equipped with modern inspection systems, and we follow globally
                recognized Total Quality Management frameworks.
              </p>
            </div>
            <div>
              <motion.img
                src="/quality.jpeg"
                alt="Quality Assurance"
                className="shadow-lg w-full object-cover h-[300px]"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              />
            </div>
          </section>
        </FadeInWhenVisible>

        <FadeInWhenVisible>
          <section className="py-12 border-t border-gray-200">
            <h2 className="text-3xl font-semibold text-center text-gray-700">
              <span className="text-[#f04b4c]">Research</span> & Development
            </h2>
            <div className="grid lg:grid-cols-2 gap-12 mt-8 items-center">
              <motion.img
                src="/rd1.jpg"
                alt="R&D Team"
                className="shadow-lg w-full object-cover h-[300px]"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              />
              <p className="text-gray-600 leading-relaxed">
                Our dedicated R&D team collaborates with international experts
                to stay at the forefront of advancements in water jet and air
                jet machinery. Integration with SAP Hana ERP ensures streamlined
                processes and timely delivery.
              </p>
            </div>
          </section>
        </FadeInWhenVisible>

        <FadeInWhenVisible>
          <section className="py-12 border-t border-gray-200">
            <h2 className="text-3xl font-semibold text-center text-gray-700">
              Testing <span className="text-[#f04b4c]">Capabilities</span>
            </h2>
            <div className="mt-8 space-y-4">
              <p className="text-gray-600 leading-relaxed text-center">
                At Frontline, we ensure product reliability through rigorous
                testing using advanced laboratory equipment. Key testing aspects
                include:
              </p>
              <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
                {[
                  "Denier testing",
                  "Hardness testing",
                  "Twist testing",
                  "Dye Pick up",
                  "Hose knitting",
                  "Fancy yarn effect testing",
                  "Weight test",
                ].map((test, index) => (
                  <motion.li
                    key={index}
                    className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow-md shadow-black/5 border-t-2 border-[#f04b4c]"
                    initial={{ opacity: 0, x: -30 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <span className="text-[#f04b4c] text-lg">•</span>
                    <span className="text-gray-700">{test}</span>
                  </motion.li>
                ))}
              </ul>
            </div>
          </section>
        </FadeInWhenVisible>

        {/* Environment Section */}
        {/* <section className="py-12 border-t border-gray-200">
          <h2 className="text-3xl font-semibold text-center text-gray-700">
            Environmental Responsibility
          </h2>
          <div className="mt-8 space-y-6">
            <p className="text-gray-600 leading-relaxed text-center">
              Frontline ensures eco-friendly operations by utilizing advanced
              Effluent Treatment Plants (ETPs) and adhering to global
              environmental standards.
            </p>
          </div>
        </section> */}
        <FadeInWhenVisible>
          <section className="py-12 border-t border-gray-200">
            <h2 className="text-3xl font-semibold text-center text-gray-700">
              Environmental Responsibility
            </h2>
            <div className="mt-8 space-y-6">
              <p className="text-gray-600 leading-relaxed text-center">
                Frontline ensures eco-friendly operations by utilizing advanced
                Effluent Treatment Plants (ETPs) and adhering to global
                environmental standards.
              </p>
            </div>
          </section>
        </FadeInWhenVisible>

        {/* Image Grid */}
        {/* <section className="py-12 border-t border-gray-200">
          <h2 className="text-3xl font-semibold text-center text-gray-700">
            Our Facility
          </h2>
          <div className="grid sm:grid-cols-3 lg:grid-cols-4 gap-6 mt-8">
            {["/m5.jpeg", "/m2.jpeg", "/m3.jpeg", "/m4.jpeg"].map(
              (src, index) => (
                <div key={index} className="relative h-[200px]">
                  <img
                    src={src}
                    alt={`Facility ${index + 1}`}
                    className="object-cover w-full h-full shadow-sm"
                  />
                </div>
              )
            )}
          </div>
        </section> */}

        <FadeInWhenVisible>
          <section className="py-12 border-t border-gray-200">
            <h2 className="text-3xl font-semibold text-center text-gray-700">
              Our <span className="text-[#f04b4c]">Facility</span>
            </h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-4  gap-10 mt-10 px-5">
              {["/m5.jpeg", "/m2.jpeg", "/m3.jpeg", "/m4.jpeg"].map(
                (src, index) => (
                  <motion.div
                    key={index}
                    className="relative group overflow-hidden rounded-2xl bg-white border-4 border-gray-300 shadow-xl p-2 transition-all duration-500 hover:border-[#f04b4c] hover:shadow-2xl hover:scale-105"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.6,
                      delay: index * 0.3,
                      ease: "easeOut",
                    }}
                  >
                    <img
                      src={src}
                      alt={`Facility ${index + 1}`}
                      className="object-cover w-full h-[340px] rounded-xl transition-all duration-500 group-hover:scale-110 group-hover:rotate-1"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity duration-500 flex items-center justify-center">
                      <span className="text-white font-semibold text-lg drop-shadow-lg">
                        Facility {index + 1}
                      </span>
                    </div>
                  </motion.div>
                )
              )}
            </div>
          </section>
        </FadeInWhenVisible>
      </div>
    </div>
  );
};

export default Quality;
