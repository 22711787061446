import { motion } from "framer-motion";
import React from "react";

const features = [
  {
    id: 1,
    title: "High Quality",
    description: "We are committed to delivering premium textiles that meet the highest standards.",
    image: "/i1.svg",
  },
  {
    id: 2,
    title: "Timely Delivery",
    description: "Ensuring on-time and full delivery of products to our valued customers.",
    image: "/i2.svg",
  },
  {
    id: 3,
    title: "Affordable Pricing",
    description: "We keep our prices competitive while maintaining top-notch quality.",
    image: "/i3.svg",
  },
  {
    id: 4,
    title: "Sustainable Materials",
    description: "Sourcing raw materials from global leaders to ensure eco-friendliness.",
    image: "/i4.svg",
  },
  {
    id: 5,
    title: "Large Production Capacity",
    description: "With state-of-the-art facilities, we provide unparalleled fabric range.",
    image: "/i5.svg",
  },
  {
    id: 6,
    title: "Industry Expertise",
    description: "Over four decades of industry experience, ensuring unmatched excellence.",
    image: "/i6.svg",
  },
  {
    id: 7,
    title: "Research & Development",
    description: "Constant innovation and improvements for a future-ready textile industry.",
    image: "/i7.svg",
  },
  {
    id: 8,
    title: "Ethical Responsibility",
    description: "We uphold ethical business practices to support communities and the environment.",
    image: "/i8.svg",
  },
  {
    id: 9,
    title: "Sustainability Practices",
    description: "Our manufacturing process minimizes environmental impact.",
    image: "/i9.svg",
  },
  {
    id: 10,
    title: "Expert Team",
    description: "A highly skilled workforce ensures excellence in every product.",
    image: "/i10.svg",
  },
  {
    id: 11,
    title: "Global Reach",
    description: "Serving customers in over 45 countries with customized solutions.",
    image: "/i11.svg",
  },
  {
    id: 12,
    title: "Advanced Traceability",
    description: "Ensuring transparency and accountability at every production stage.",
    image: "/i12.svg",
  },
];

const WhyVSM = () => {
  return (
    <section className="py-16 bg-gray-100 mt-20 mb-10 text-gray-900">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-3xl font-semibold mb-4">Why Frontline</h2>
        <p className="text-gray-600 mb-10">
          As a leader in the textile industry, Frontline combines quality, innovation, and sustainability.
        </p>
      </div>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl p-4 mx-auto"
      >
        {features.map((feature, index) => (
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.05 }}
            key={feature.id}
            className="bg-white px-5 py-16 rounded-lg border relative"
          >
            <div className="absolute top-4 right-4 text-[#f04b4c] text-4xl font-semibold">
              {String(index + 1).padStart(2, '0')}
            </div>
            <div className="flex items-center mb-4">
              <img src={feature.image} alt={feature.title} className="w-10 h-10 mr-3" />
              <h3 className="text-2xl font-semibold">{feature.title}</h3>
            </div>
            <p className="text-gray-600">{feature.description}</p>
          </motion.div>
        ))}
      </motion.div>
    </section>
  );
};

export default WhyVSM;