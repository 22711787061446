"use client";
import React from "react";
import { motion } from "framer-motion";

const auditSteps = [
    {
        title: "Organize",
        description: "We systematically arrange and remove unnecessary items for optimal efficiency.",
        icon: "https://cdn-icons-png.flaticon.com/512/3208/3208735.png",
        borderColor: "border-orange-500",
    },
    {
        title: "Systemize",
        description: "Structured placement ensures easy accessibility and reduces time waste.",
        icon: "https://cdn-icons-png.flaticon.com/512/2784/2784467.png",
        borderColor: "border-orange-500",
    },
    {
        title: "Clean",
        description: "A well-maintained workspace fosters safety and productivity.",
        icon: "https://cdn-icons-png.flaticon.com/512/3522/3522339.png",
        borderColor: "border-orange-500",
    },
    {
        title: "Standardize",
        description: "We implement rules to sustain the 5S methodology effectively.",
        icon: "https://cdn-icons-png.flaticon.com/512/1090/1090801.png",
        borderColor: "border-orange-500",
    },
    {
        title: "Sustain",
        description: "Ongoing improvements ensure a culture of continuous enhancement.",
        icon: "https://cdn-icons-png.flaticon.com/512/753/753317.png",
        borderColor: "border-orange-500",
    },
];

const Fivesaudit = () => {
    return (
        <section className="bg-gray-100 py-20 px-4">
            <motion.div
                className="max-w-6xl mx-auto text-center"
                initial={{ opacity: 0, y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, ease: "easeOut" }}
                viewport={{ amount: 0.5 }}
            >
                <h2 className="text-3xl font-semibold text-blue-900">5S AUDIT</h2>
                <p className="text-gray-700 mt-2 max-w-2xl mx-auto">
                    Our organization proudly upholds the prestigious 5S audit certification, showcasing our dedication to efficiency and excellence.
                </p>
            </motion.div>

            <div className="max-w-6xl mx-auto mt-10 p-6 border-2 border-orange-500 rounded-lg flex flex-col md:flex-row items-center justify-between gap-6">
                {auditSteps.map((step, index) => (
                    <motion.div
                        key={index}
                        className="flex-1 text-center p-4"
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: "easeOut", delay: index * 0.2 }}
                        viewport={{ amount: 0.5 }}
                    >
                        <div className={`border-2 rounded-full p-4 w-20 h-20 mx-auto flex items-center justify-center ${step.borderColor}`}>
                            <img src={step.icon} alt={step.title} className="w-12 h-12" />
                        </div>
                        <h3 className="text-lg font-semibold text-blue-900 mt-4">{step.title}</h3>
                        <p className="text-gray-700 text-sm mt-2">{step.description}</p>
                    </motion.div>
                ))}
            </div>
        </section>
    );
};

export default Fivesaudit;


// "use client";
// import React from "react";
// import { motion } from "framer-motion";

// const auditSteps = [
//     {
//         title: "Organize",
//         description: "We systematically arrange and remove unnecessary items for optimal efficiency.",
//         icon: "https://cdn-icons-png.flaticon.com/512/3208/3208735.png",
//     },
//     {
//         title: "Systemize",
//         description: "Structured placement ensures easy accessibility and reduces time waste.",
//         icon: "https://cdn-icons-png.flaticon.com/512/2784/2784467.png",
//     },
//     {
//         title: "Clean",
//         description: "A well-maintained workspace fosters safety and productivity.",
//         icon: "https://cdn-icons-png.flaticon.com/512/3522/3522339.png",
//     },
//     {
//         title: "Standardize",
//         description: "We implement rules to sustain the 5S methodology effectively.",
//         icon: "https://cdn-icons-png.flaticon.com/512/1090/1090801.png",
//     },
//     {
//         title: "Sustain",
//         description: "Ongoing improvements ensure a culture of continuous enhancement.",
//         icon: "https://cdn-icons-png.flaticon.com/512/753/753317.png",
//     },
// ];

// const FivesAudit = () => {
//     return (
//         <section className="bg-gray-50 py-20 px-4">
//             <motion.div
//                 className="max-w-5xl mx-auto text-center"
//                 initial={{ opacity: 0, y: -30 }}
//                 whileInView={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.6, ease: "easeOut" }}
//                 viewport={{ amount: 0.5 }}
//             >
//                 <h2 className="text-4xl font-bold text-gray-900">5S AUDIT</h2>
//                 <p className="text-gray-600 mt-3 max-w-2xl mx-auto">
//                     Our organization proudly upholds the prestigious 5S audit certification, showcasing our dedication to efficiency and excellence.
//                 </p>
//             </motion.div>

//             <div className="max-w-6xl mx-auto mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6">
//                 {auditSteps.map((step, index) => (
//                     <motion.div
//                         key={index}
//                         className="bg-white shadow-lg rounded-lg p-6 text-center hover:shadow-2xl transition-all duration-300"
//                         initial={{ opacity: 0, y: 30 }}
//                         whileInView={{ opacity: 1, y: 0 }}
//                         transition={{ duration: 0.5, ease: "easeOut", delay: index * 0.1 }}
//                         viewport={{ amount: 0.5 }}
//                     >
//                         <div className="w-20 h-20 mx-auto flex items-center justify-center bg-gradient-to-r from-orange-400 to-orange-600 rounded-full p-3">
//                             <img src={step.icon} alt={step.title} className="w-12 h-12" />
//                         </div>
//                         <h3 className="text-lg font-semibold text-gray-900 mt-4">{step.title}</h3>
//                         <p className="text-gray-600 text-sm mt-2">{step.description}</p>``
//                     </motion.div>
//                 ))}
//             </div>
//         </section>
//     );
// };

// export default FivesAudit;

// "use client";
// import React, { useState } from "react";
// import { motion } from "framer-motion";

// const auditSteps = [
//     {
//         title: "Organize",
//         description: "We systematically arrange and remove unnecessary items for optimal efficiency.",
//         icon: "https://cdn-icons-png.flaticon.com/512/3208/3208735.png",
//     },
//     {
//         title: "Systemize",
//         description: "Structured placement ensures easy accessibility and reduces time waste.",
//         icon: "https://cdn-icons-png.flaticon.com/512/2784/2784467.png",
//     },
//     {
//         title: "Clean",
//         description: "A well-maintained workspace fosters safety and productivity.",
//         icon: "https://cdn-icons-png.flaticon.com/512/3522/3522339.png",
//     },
//     {
//         title: "Standardize",
//         description: "We implement rules to sustain the 5S methodology effectively.",
//         icon: "https://cdn-icons-png.flaticon.com/512/1090/1090801.png",
//     },
//     {
//         title: "Sustain",
//         description: "Ongoing improvements ensure a culture of continuous enhancement.",
//         icon: "https://cdn-icons-png.flaticon.com/512/753/753317.png",
//     },
// ];

// const FivesAudit = () => {
//     const [activeIndex, setActiveIndex] = useState(null);

//     const handleCardClick = (index) => {
//         setActiveIndex(activeIndex === index ? null : index);
//     };

//     return (
//         <section className="bg-gray-100 py-10 px-4">
//             <div className="max-w-5xl mx-auto text-center mb-10">
//                 <h2 className="text-4xl font-bold text-gray-900">5S AUDIT</h2>
//                 <p className="text-gray-700 mt-4">
//                     Our organization proudly upholds the prestigious 5S audit certification, showcasing our dedication to efficiency and excellence.
//                 </p>
//             </div>

//             <div className="flex flex-wrap justify-center gap-6">
//                 {auditSteps.map((step, index) => (
//                     <motion.div
//                         key={index}
//                         className="w-full sm:w-60 bg-white rounded-lg shadow-lg p-4 cursor-pointer transform transition-transform duration-300 hover:scale-105"
//                         onClick={() => handleCardClick(index)}
//                     >
//                         <div className="flex flex-col items-center">
//                             <div className="w-16 h-16 mb-4 flex items-center justify-center bg-gradient-to-r from-blue-500 to-green-500 rounded-full">
//                                 <img src={step.icon} alt={step.title} className="w-12 h-12" />
//                             </div>
//                             <h3 className="text-xl font-semibold text-gray-900">{step.title}</h3>
//                             {activeIndex === index && (
//                                 <p className="text-gray-600 mt-2">{step.description}</p>
//                             )}
//                         </div>
//                     </motion.div>
//                 ))}
//             </div>
//         </section>
//     );
// };

// export default FivesAudit;