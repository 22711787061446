const Technology = () => {
    return (
        <div className="container lg:max-w-7xl mx-auto mt-10">
            <h3 className="font-semibold text-3xl mb-2">Cutting-Edge Technology for Superior Quality</h3>
            <p className="mb-5">At Frontline, we strive for excellence in every aspect of our operations. With a focus on continuous upgradation, we invest in modern machinery and cutting-edge technology to ensure world-class quality. Our spinning infrastructure is constantly updated to achieve the highest productivity with the lowest power consumption, maintaining superior quality and consistency in every product we produce.</p>
            <img src="/m1.jpeg" className="lg:h-[500px] w-full object-cover" alt="" />
        </div>
    );
};

export default Technology;