import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const changingWords = ["Fabric", "Quality", "Trust", "precise", "Luxury"];

const ThinkVSMSection = () => {
    const [wordIndex, setWordIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setWordIndex((prevIndex) => (prevIndex + 1) % changingWords.length);
        }, 2500);
        return () => clearInterval(interval);
    }, []);

    return (
        <section className="flex flex-col items-center justify-center text-center py-10 px-4">
            {/* Hero Section */}
            <div className="bg-white rounded-lg">
                <h1 className="text-2xl sm:text-4xl md:text-6xl font-semibold flex items-center justify-center gap-2 lg:gap-4 text-center">
                    Think
                    {/* Fixed Width for Changing Text */}
                    <div className="relative flex items-center justify-center min-w-[100px] sm:min-w-[230px] min-h-[70px] overflow-hidden">
                        <AnimatePresence mode="wait">
                            <motion.span
                                key={wordIndex}
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                transition={{ duration: 0.5 }}
                                className="absolute inset-0 flex items-center justify-center text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-orange-500 leading-none"
                            >
                                {changingWords[wordIndex]}
                            </motion.span>
                        </AnimatePresence>
                    </div>

                    Think
                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-orange-500 whitespace-nowrap inline-block">
                        Frontline
                    </span>
                </h1>
            </div>
            {/* Statistics Section */}
            <motion.div
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 mt-10 px-6 md:px-20"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: {
                        opacity: 1,
                        y: 0,
                        transition: { staggerChildren: 0.3 }
                    }
                }}
            >
                {[
                    { title: "Fabric Production", value: "45+", description: "Million Meter Per Annum Fabrics Weaving Capacity." },
                    { title: "High-Quality Fabrics", value: "1000+", description: "Fabric Types." },
                    { title: "Weaving Facilities", value: "7+", description: "High-Standard Weaving Facilities." },
                    { title: "Weaving", value: "500+", description: "High-Speed Airjet & High-Speed Waterjet Machineries." }
                ].map((item, index) => (
                    <motion.div
                        key={index}
                        className="bg-orange-100 p-8 rounded-lg text-center transition-all duration-300 hover:scale-105"
                        // variants={{
                        //     hidden: { opacity: 0, y: 50 },
                        //     visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
                        // }}
                    >
                        <h2 className="text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-orange-500 pb-4">
                            {item.title}
                        </h2>
                        <p className="text-4xl font-semibold pb-2">{item.value}</p>
                        <p className="text-gray-600 text-lg">{item.description}</p>
                    </motion.div>
                ))}
            </motion.div>
        </section>
    );
};

export default ThinkVSMSection;
