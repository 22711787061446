import { useEffect } from "react";
import Form from "../components/contact/form";
import { ContactHero } from "../components/contact/hero";
import { VisitUs } from "../components/about/visit-us";
import ContactSection from "../components/contact/contact-grid";

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <ContactHero />
            <Form />
            <VisitUs />
            <div className="container mt-20 mb-20">
                <iframe title="address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119009.19641730085!2d72.75852413682514!3d21.23036594910736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0448321b00843%3A0xe89fb64ba12bdc58!2sOm%20Industries!5e0!3m2!1sen!2sin!4v1728035839823!5m2!1sen!2sin" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <ContactSection />
        </>
    );
};

export default Contact;