import { useNavigate } from "react-router-dom"

const QualityAssurance = () => {
  const navigate = useNavigate()

  return (
    <section className="container mx-auto flex flex-col md:flex-row items-center justify-between w-full bg-white py-12 px-6 md:px-12 mt-10 rounded-2xl shadow-lg border-t-4 border-[#f04b4c]">
      {/* Image Section */}
      <div className="relative w-full md:w-1/2 h-[300px] md:h-[400px] overflow-hidden rounded-xl shadow-md">
        <div className="absolute inset-0 bg-[#f04b4c] opacity-10 z-10 rounded-xl"></div>
        <img
          src="m8.jpeg"
          alt="Quality Assurance"
          className="w-full h-full object-cover transition-transform duration-500 hover:scale-105"
        />
        <div className="absolute top-0 left-0 w-20 h-20 bg-[#f04b4c] rounded-br-3xl flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </div>

      {/* Content Section */}
      <div className="w-full md:w-1/2 flex flex-col items-center md:items-start justify-center text-center md:text-left mt-8 md:mt-0 lg:ms-12">
        <div className="flex items-center mb-2">
          <div className="w-10 h-1 bg-[#f04b4c] mr-3 rounded-full"></div>
          <span className="text-[#f04b4c] font-medium uppercase tracking-wider text-sm">Excellence</span>
        </div>
        <h1 className="text-2xl md:text-4xl font-semibold text-gray-900">Quality Assurance</h1>
        <p className="text-gray-600 mt-4 text-base md:text-lg max-w-lg leading-relaxed">
          Discover Frontline's extensive range of textile fabrics for apparel, ethnic wear, home furnishings, and
          technical textiles. With a commitment to quality and sustainability, we provide personalized solutions for all
          your fabric needs.
        </p>
        <button
          onClick={() => navigate("/quality")}
          className="mt-8 px-8 py-3 bg-[#f04b4c] text-white font-medium rounded-lg shadow-md transition-all duration-300 hover:bg-[#d43c3d] hover:shadow-lg hover:-translate-y-1 focus:outline-none focus:ring-2 focus:ring-[#f04b4c] focus:ring-opacity-50"
        >
          Know More
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 ml-2 inline-block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </button>

        <div className="flex items-center mt-6 space-x-4">
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-[#f04b4c]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            <span className="ml-2 text-gray-700">Premium Quality</span>
          </div>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-[#f04b4c]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            <span className="ml-2 text-gray-700">Sustainable</span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default QualityAssurance