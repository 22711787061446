import { Mail, Facebook, Twitter, Instagram } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { navigateToEmail } from "./headline";

export const Footer = () => {
    const navigate = useNavigate();

    return (
        <div className=''>
            <div className="bg-neutral-800 text-white pt-10 mt-10 pb-5 w-full">
                <div className="container lg:grid lg:grid-cols-5 gap-x-[100px]">
                    <div className="col-span-2">
                        <div className="lg:text-xl mt-5">
                            Frontline Textiles is the trusted partner of the finest tailors, carefully selected for their exceptional service and dedication to their clients' needs.
                        <p className="mt-4">Inquiry: +91 78782 51565</p>
                        </div>
                    </div>
                    <div className="col-span-3 mt-10 lg:mt-0">
                        <div className="grid lg:grid-cols-2 gap-x-[50px] lg:text-xl">
                            <div>
                                <div className="font-[500]">Menu</div>
                                <div className="h-[1px] bg-white my-5" />
                                <div className="flex flex-col gap-y-3 lg:gap-y-5 font-[500]">
                                    {/* <div onClick={() => navigate('/company')} className="cursor-pointer hover:text-[#f04b4c] transition-all">
                                        Company
                                    </div> */}
                                    {/* <div onClick={() => navigate('/service')} className="cursor-pointer hover:text-[#f04b4c] transition-all">
                                        Services
                                    </div> */}
                                    <div onClick={() => navigate('/collection')} className="cursor-pointer hover:text-[#f04b4c] transition-all">
                                        Collection
                                    </div>
                                    <div onClick={() => navigate('/contact-us')} className="cursor-pointer hover:text-[#f04b4c] transition-all">
                                        Contact Us
                                    </div>
                                    <div onClick={() => navigate('/quality')} className="cursor-pointer hover:text-[#f04b4c] transition-all">
                                        Quality
                                    </div>
                                    <div onClick={() => navigate('/sustainability')} className="cursor-pointer hover:text-[#f04b4c] transition-all">
                                        Sustainability
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10 lg:mt-0">
                                <div className="font-[500]">Get in touch!</div>
                                <div className="h-[1px] bg-white my-5" />
                                <div className="flex space-x-4 pt-4">
                                    <div className="bg-neutral-700 p-2 cursor-pointer">
                                        <Facebook className="w-5 h-5 text-white" />
                                    </div>
                                    <div className="bg-neutral-700 p-2 cursor-pointer">
                                        <Twitter className="w-5 h-5 text-white" />
                                    </div>
                                    <div className="bg-neutral-700 p-2 cursor-pointer">
                                        <Instagram className="w-5 h-5 text-white" />
                                    </div>
                                    <div onClick={navigateToEmail} className="bg-neutral-700 p-2 cursor-pointer">
                                        <Mail className="w-5 h-5 text-white" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full">
                    <div className="h-[0.5px] bg-white/10 mt-12 mb-4" />
                    <div className="container text-center text-xs lg:text-[16px]">
                        Copyright © {new Date().getFullYear()} FrontLine All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    );
};