import { ChevronRight } from "lucide-react";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";

export const Trust = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="mt-10 mb-10 lg:mt-10 rounded-b-3xl relative h-[600px]">
                <img src="/m1.jpg" alt="" className="absolute w-full h-full object-cover brightness-[0.4]" />
                <div className="container flex justify-center">
                    <div className="flex h-full justify-center items-center absolute top-0 text-white">
                        <div className="text-center">
                            <div className="text-3xl mt-3 lg:mt-0 lg:text-4xl font-[500]">
                                No Limits to Your{" "}<span className="text-[#f04b4c]">Imagination!</span>
                            </div>
                            <p className="text-white lg:max-w-[550px] text-sm tracking-wide mt-5 p-5 lg:p-0">
                                At Frontline Textiles, we&apos;re here to help bring even your boldest ideas to life. We understand that true professionals pay close attention to every detail, which is why we continually update our fabric collection with the best and most innovative options, ensuring that your creative possibilities are endless.
                                <br /> <br />
                                If you're looking to refresh your home&apos;s interior but want to avoid making major changes, we offer a wide selection of durable, high-quality fabrics perfect for upholstery. These materials will breathe new life into your furniture, transforming the look of any room.
                            </p>
                            {/* <Button
                                variant="custom"
                                className="mt-5 font-[500] bg-white text-black hover:bg-white hover:text-black"
                                onClick={() => navigate('/contact-us')}
                            >
                                Collab Now <ChevronRight className="pb-1" />
                            </Button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}