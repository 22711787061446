// import { Mail, MapPin } from "lucide-react";
// import { Button } from "../ui/button";
// import { FaWhatsapp } from "react-icons/fa";
// import { FiPhone } from "react-icons/fi";
// import { navigateToEmail } from "../headline";

// export const VisitUs = () => {
//   const phoneNumber = "+917878251565";

//   return (
//     <div className="bg-gray-50 mt-20">
//       <div className="container text-center py-10">
//         <h1 className="text-3xl font-[500] lg:text-5xl">Visit</h1>
//         <div className="text-center py-10">
//           <h2 className="text-2xl font-[500] lg:text-2xl">Our Corporate Office</h2>
//           <p className="flex items-center justify-center gap-x-3 my-4">
//             <MapPin className="hidden lg:block" /> A-1037 to 1039, Radha Raman
//             Textile Market 2 (RRTM 2), Saroli, Surat-395010, Gujarat, India.
//           </p>
//         </div>

//         <h2 className="text-2xl font-[500] lg:text-2xl">Our Manufacturing Plant</h2>
//         <p className="flex items-center justify-center gap-x-3 my-4">
//           <MapPin className="hidden lg:block" />Plot No. 39 To 49, Om
//           Textile Park,Vibhag-8. Umbhel,Pared road, Kamrej, Surat, Gujarat,
//           394180
//         </p>
//         <p className="flex items-center justify-center gap-x-3 my-4">
//           <MapPin className="hidden lg:block" />Plot No. 13 to 16 & 60 to
//           63, Om Textile Park,Vibhag-19, Umbhel,Pared road, Kamrej, Surat,
//           Gujarat, 394180
//         </p>
//         <div className="flex flex-wrap justify-center gap-x-4">
//           <Button
//             variant="customoutline"
//             className="mt-5 font-[500] flex items-center gap-x-3"
//             onClick={() => window.open(`tel:${phoneNumber}`, "_blank")}
//           >
//             <FiPhone size={20} /> Call Us Now
//           </Button>
//           <Button
//             variant="customoutline"
//             className="mt-5 font-[500] flex items-center gap-x-3"
//             onClick={navigateToEmail}
//           >
//             <Mail size={20} /> Send Us an Email
//           </Button>
//           <Button
//             variant="customoutline"
//             className="mt-5 font-[500] flex items-center gap-x-3"
//             onClick={() =>
//               window.open(`https://wa.me/${phoneNumber}`, "_blank")
//             }
//           >
//             <FaWhatsapp size={20} /> Chat on Whatsapp
//           </Button>
//         </div>
//       </div>
//     </div>
//   );
// };

import { Mail, MapPin } from "lucide-react";
import { Button } from "../ui/button";
import { FaWhatsapp } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { navigateToEmail } from "../headline";
import { motion } from "framer-motion";
import { useRef } from "react";
import { useInView } from "framer-motion";

export const VisitUs = () => {
  const phoneNumber = "+917878251565";
  
  const ref = useRef(null);
  const isInView = useInView(ref, { triggerOnce: false, threshold: 0.2 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.6, ease: "easeOut" }}
      className="bg-gray-50 mt-20"
    >
      <div className="container text-center py-10">
        <motion.h1
          className="text-3xl font-[500] lg:text-5xl"
          initial={{ opacity: 0, y: -20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ delay: 0.2, duration: 0.6 }}
        >
          Visit
        </motion.h1>

        <div className="text-center py-10">
          <motion.h2
            className="text-2xl font-[500] lg:text-2xl"
            initial={{ opacity: 0, y: -20 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ delay: 0.3, duration: 0.6 }}
          >
            Our Corporate Office
          </motion.h2>
          <motion.p
            className="flex justify-center my-4 lg:max-w-[600px] mx-auto"
            initial={{ opacity: 0, y: 30 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ delay: 0.4, duration: 0.6 }}
          >
            <MapPin className="hidden lg:block" /> A-1037 to 1039, Radha Raman Textile Market 2 (RRTM 2), Saroli, Surat-395010, Gujarat, India.
          </motion.p>
        </div>

        <motion.h2
          className="text-2xl font-[500] lg:text-2xl"
          initial={{ opacity: 0, y: -20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ delay: 0.5, duration: 0.6 }}
        >
          Our Manufacturing Plant
        </motion.h2>

        {[ 
          "Plot No. 39 To 49, Om Textile Park, Vibhag-8, Umbhel, Pared road, Kamrej, Surat, Gujarat, 394180",
          "Plot No. 13 to 16 & 60 to 63, Om Textile Park, Vibhag-19, Umbhel, Pared road, Kamrej, Surat, Gujarat, 394180"
        ].map((address, index) => (
          <motion.p
            key={index}
            className="flex justify-center my-4 lg:max-w-[600px] mx-auto"
            initial={{ opacity: 0, y: 30 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ delay: 0.6 + index * 0.2, duration: 0.6 }}
          >
            <MapPin className="hidden lg:block" /> {address}
          </motion.p>
        ))}

        <motion.div
          className="flex flex-wrap justify-center gap-x-4"
          initial={{ opacity: 0, y: 30 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ delay: 0.8, duration: 0.6 }}
        >
          <Button
            variant="customoutline"
            className="mt-5 font-[500] flex items-center gap-x-3"
            onClick={() => window.open(`tel:${phoneNumber}`, "_blank")}
          >
            <FiPhone size={20} /> Call Us Now
          </Button>
          <Button
            variant="customoutline"
            className="mt-5 font-[500] flex items-center gap-x-3"
            onClick={navigateToEmail}
          >
            <Mail size={20} /> Send Us an Email
          </Button>
          <Button
            variant="customoutline"
            className="mt-5 font-[500] flex items-center gap-x-3"
            onClick={() => window.open(`https://wa.me/${phoneNumber}`, "_blank")}
          >
            <FaWhatsapp size={20} /> Chat on Whatsapp
          </Button>
        </motion.div>
      </div>
    </motion.div>
  );
};
