import React, { useEffect, useRef, useState } from "react";
import { GiLoincloth } from "react-icons/gi";
import { IoBulbOutline } from "react-icons/io5";
import { GrGroup } from "react-icons/gr";
import { BsEmojiSmile } from "react-icons/bs";
import CountUp from "react-countup";

export const Statistics = () => {
    const [startCount, setStartCount] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setStartCount(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <div className="bg-gray-50 mt-20" ref={sectionRef}>
            <div className="container py-10">
                <div className="grid lg:grid-cols-4 gap-y-12">
                    <div className="text-center">
                        <div className="flex justify-center"><GiLoincloth size={30} /></div>
                        <div className="text-3xl font-[600] my-2">
                            {startCount ? <CountUp end={28000} duration={2} /> : "0"}
                        </div>
                        <div>Tons of fabrics / year</div>
                    </div>
                    <div className="text-center">
                        <div className="flex justify-center"><GrGroup size={30} /></div>
                        <div className="text-3xl font-[600] my-2">
                            {startCount ? <CountUp end={200} duration={2} /> : "0"}
                        </div>
                        <div>Total Employees</div>
                    </div>
                    <div className="text-center">
                        <div className="flex justify-center"><IoBulbOutline size={30} /></div>
                        <div className="text-3xl font-[600] my-2">
                            {startCount ? <CountUp end={689} duration={2} /> : "0"}
                        </div>
                        <div>Hours Worked</div>
                    </div>
                    <div className="text-center">
                        <div className="flex justify-center"><BsEmojiSmile size={30} /></div>
                        <div className="text-3xl font-[600] my-2">
                            {startCount ? <CountUp end={500} duration={2} /> : "0"}
                        </div>
                        <div>Happy Customers</div>
                    </div>
                </div>
            </div>
        </div>
    );
};