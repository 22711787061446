import { useEffect } from "react";
import { Statistics } from "../components/about/statistics";
import { Welcome } from "../components/about/welcome";
import ClientReviewsCarousel from "../components/home/review";
import JourneySection from "../components/about/journey-section";
import { VisitUs } from "../components/about/visit-us";
import { HeadquartersGallery } from "../components/about/headquarters";
import { Team } from "../components/about/team";

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Welcome />
            <Statistics />
            <HeadquartersGallery />
            <Team />
            <div className="mb-20">
                {/* <HomeServices /> */}
            </div>
            <JourneySection />
            <div className="pb-20">
                <ClientReviewsCarousel />
            </div>
            <VisitUs />
        </>
    );
};

export default About;