import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";
import { Card, CardContent } from "../ui/card";
import Autoplay from "embla-carousel-autoplay";
import { motion } from "framer-motion";

const heroImages = [
  { name: "Polyester Textured Fabric", src: "/heroimage.jpeg" },
  { name: "Polyester Chiffon Fabric", src: "/mm1.jpg", alt: "Headquarters 3" },
  {
    name: "Checkered Polyester Fabric",
    src: "/BLENDS16.jpeg",
    alt: "Headquarters 2",
  },
];

export const Hero = () => {
  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.3, duration: 0.6, ease: "easeOut" },
    }),
  };
  return (
    <div className="">
      {/* <video
                className="h-[400px] md:h-[100vh] w-full object-cover"
                src="/hero.mp4"
                autoPlay
                loop
                muted
                playsInline
            /> */}
      <Carousel
        opts={{
          align: "start",
          loop: true,
        }}
        plugins={[
          Autoplay({
            delay: 3000,
          }),
        ]}
        className="w-full relative hidden md:block"
      >
        <CarouselContent>
          {heroImages.map((image, index) => (
            <CarouselItem key={index} className="">
              <div>
                <Card>
                  <CardContent className="flex items-center justify-center p-0 relative">
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="w-full md:h-[calc(100vh-110px)] object-cover"
                    />
                    <div
                      className={`absolute inset-0 flex items-center justify-center ${
                        index < 1 && "bg-black/40"
                      } px-6 py-4`}
                    >
                      <div className="text-white text-center max-w-2xl">
                        {index < 1 && (
                          <>
                            {/* Animated Title */}
                            <motion.h2
                              className="text-2xl md:text-3xl lg:text-4xl font-semibold"
                              initial="hidden"
                              animate="visible"
                              variants={textVariants}
                              custom={0} // Appears first
                            >
                              <span className="text-[#f04b4c]">Premium</span>{" "}
                              Textile Solutions
                            </motion.h2>

                            {/* Animated Paragraph Lines */}
                            <motion.p
                              className="mt-7 text-sm md:text-lg lg:text-xl"
                              initial="hidden"
                              animate="visible"
                            >
                              {[
                                "At Frontline, we specialize in crafting and delivering high-quality textile fabrics for a variety of industries.",
                                "With state-of-the-art production facilities and a commitment to innovation,",
                                "we take pride in being a trusted partner for businesses seeking exceptional textile solutions.",
                              ].map((line, i) => (
                                <motion.span
                                  key={i}
                                  className="block"
                                  variants={textVariants}
                                  custom={i + 1}
                                >
                                  {line}
                                </motion.span>
                              ))}
                            </motion.p>
                          </>
                        )}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <div className="absolute left-1/2 bottom-20 md:bottom-[53%] -translate-x-1/2 md:w-[80vw] lg:w-[85vw]">
          <CarouselPrevious />
          <CarouselNext />
        </div>
      </Carousel>
      <img src="/heroimage.jpeg" className="block h-[60vh] sm:h-[70vh] md:h-[calc(80vh-90px)] md:hidden" alt="" />  
    </div>
  );
};
