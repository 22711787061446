import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { categories, fabricProducts } from "../lists/product-list";
import { Card, CardContent } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";

export default function FabricCategoryPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const subcategoryParam = queryParams.get("subcategory");
  const categoryParam = queryParams.get("category");

  const [selectedCategory, setSelectedCategory] = useState(categoryParam || null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(subcategoryParam || null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  useEffect(() => {
    if (categoryParam) {
      setSelectedCategory(categoryParam);
    }
    if (subcategoryParam) {
      setSelectedSubcategory(subcategoryParam === "linen-blends" ? "Linen Blends" : subcategoryParam);
    }
  }, [categoryParam, subcategoryParam]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const updateFilters = (category = null, subcategory = null) => {
    const params = new URLSearchParams();
    if (category) params.set("category", category);
    if (subcategory) params.set("subcategory", subcategory);
    navigate({ search: params.toString() });
  };

  const clearFilters = () => {
    setSelectedCategory(null);
    setSelectedSubcategory(null);
    setCurrentPage(1);
    navigate({ search: "" });
  };

  const filteredProducts = fabricProducts.filter(
    (product) =>
      (!selectedCategory || product.category === selectedCategory) &&
      (!selectedSubcategory || product.subcategory === selectedSubcategory)
  );

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const currentProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="min-h-screen bg-gray-100">
      <div
        className="relative h-80 bg-cover bg-center"
        style={{ backgroundImage: "url('/f7.jpeg?height=400&width=1200')" }}
      >
        <div className="container absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h1 className="text-3xl lg:text-5xl font-semibold text-white">Fabric <span className="text-[#f04b4c]">Emporium</span></h1>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row">
          <aside className="w-full md:w-1/4 pr-8 mb-8 md:mb-0">
            <div className="mb-4 flex justify-between items-center">
              <h2 className="text-xl font-semibold">Categories</h2>
              <Button variant="outline" onClick={clearFilters} className="text-sm">Clear Filters</Button>
            </div>
            <ul className="space-y-2">
              {categories &&
                Object.keys(categories).map((category) => (
                  <Accordion type="single" collapsible key={category}>
                    <AccordionItem value={category}>
                      <div className="flex items-center justify-between">
                        <div
                          onClick={() => {
                            setSelectedCategory(category);
                            setSelectedSubcategory(null);
                            setCurrentPage(1);
                            updateFilters(category, null);
                          }}
                          className={`cursor-pointer ${selectedCategory === category ? 'font-semibold' : ''}`}
                        >
                          {category}
                        </div>
                        <AccordionTrigger />
                      </div>
                      <AccordionContent>
                        <ul>
                          {categories[category].map((subcategory) => (
                            <li key={subcategory}>
                              <Button
                                variant={selectedSubcategory === subcategory ? "default" : "outline"}
                                onClick={() => {
                                  setSelectedSubcategory(subcategory);
                                  setCurrentPage(1);
                                  updateFilters(category, subcategory);
                                }}
                                className={`w-full justify-start ${selectedSubcategory === subcategory ? 'bg-blue-500 text-white' : ''}`}
                              >
                                {subcategory}
                              </Button>
                            </li>
                          ))}
                        </ul>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                ))}
            </ul>
          </aside>

          <main className="w-full md:w-3/4">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {currentProducts.map((product) => (
                <motion.div key={product.id}>
                  <div className="cursor-pointer duration-300 border p-0">
                    <CardContent className="p-0 pb-4">
                      <div className="relative mb-4">
                        <img
                          src={product.image || "/placeholder.svg"}
                          alt={product.name}
                          className="w-full lg:h-[300px] object-cover"
                        />
                      </div>
                      <h3 className="text-lg font-semibold px-3">{product.name}</h3>
                      <p className="text-sm text-gray-600 px-3">
                        {product.category} - {product.subcategory}
                      </p>
                    </CardContent>
                  </div>
                </motion.div>
              ))}
            </div>

            {totalPages > 1 && (
              <div className="flex justify-center mt-8 space-x-4">
                <Button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
                  Previous
                </Button>
                <span className="text-lg font-semibold">Page {currentPage} of {totalPages}</span>
                <Button disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)}>
                  Next
                </Button>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  );
}