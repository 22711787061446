export const productList = [
    {
        name: "Silk Satin",
        imageUrl: "/1.jpg",
    },
    {
        name: "Italian Wool",
        imageUrl: "/2.jpg",
    },
    {
        name: "Luxury Velvet",
        imageUrl: "/3.jpg",
    },
    {
        name: "Chiffon",
        imageUrl: "/4.jpg",
    },
    {
        name: "Velvet Blend",
        imageUrl: "/4.jpg",
    },
    {
        name: "Soft Wool",
        imageUrl: "/3.jpg",
    },
    {
        name: "Elegant Satin",
        imageUrl: "/2.jpg",
    },
    {
        name: "Flowy Chiffon",
        imageUrl: "/1.jpg",
    },
    {
        name: "Satin Charm",
        imageUrl: "/1.jpg",
    },
    {
        name: "Rich Wool",
        imageUrl: "/2.jpg",
    },
    {
        name: "Velvet Elegance",
        imageUrl: "/3.jpg",
    },
    {
        name: "Chiffon Grace",
        imageUrl: "/4.jpg",
    },
    {
        name: "Wool Classic",
        imageUrl: "/3.jpg",
    },
    {
        name: "Velvet Supreme",
        imageUrl: "/4.jpg",
    },
    {
        name: "Satin Shine",
        imageUrl: "/1.jpg",
    },
    {
        name: "Chiffon Luxe",
        imageUrl: "/2.jpg",
    },
];

export const weaves = [
    "Plain Weave",
    "Twill Weave",
    "Satin Weave",
    "Jacquard Weave",
    // "Ribs Weave",
    // "Stripes Weave",
    // "Checks Weave",
    // "Pile Weave",
    // "Crepes Weave",
    "Double Cloth Weave",
    "Dobby Weave",
    // "Leno Weave",
];

export const categories = {
    Natural: ["Cotton", "Viscose", "Linen"],
    Synthetic: [
        "Polyester", 
        // "Nylon", 
        // "Catonic", 
        // "BSY", 
        // "CEY"
    ],
    Blends: [
        "N x V",
        "P x V",
        // "N x C",
        "Polyster Velvet",
        "P - V - Linen",
        "Viscose Slub",
        // "Viscose Cotton",
        // "Cotton Flex",
        "Viscose Lycra",
    ],
    Other: [
        "Bubble", 
        "Dobby", 
        "High Twist",
        "Jacquard",
        "Pattern",
        // "Mechanical Stretch",
        "Print"
    ],
};

export const fabricProducts = [
    {
        id: Math.random(),
        name: "SKY LAND DENT",
        category: "Natural",
        subcategory: categories.Natural[0],
        image: "/COTTON1.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND SANIL",
        category: "Natural",
        subcategory: categories.Natural[0],
        image: "/COTTON2.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HARINGBORN",
        category: "Natural",
        subcategory: categories.Natural[0],
        image: "/COTTON3.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENT",
        category: "Natural",
        subcategory: categories.Natural[0],
        image: "/COTTON8.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND NEW",
        category: "Natural",
        subcategory: categories.Natural[0],
        image: "/COTTON5.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENT",
        category: "Natural",
        subcategory: categories.Natural[0],
        image: "/COTTON9.jpeg",
    },
    {
        id: Math.random(),
        name: "PV MUSLIN",
        category: "Natural",
        subcategory: categories.Natural[0],
        image: "/COTTON6.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HEAVY",
        category: "Natural",
        subcategory: categories.Natural[0],
        image: "/COTTON7.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENT",
        category: "Natural",
        subcategory: categories.Natural[0],
        image: "/COTTON9.jpeg",
    },
    {
        id: Math.random(),
        name: "PV GADHWAL",
        category: "Blends",
        subcategory: categories.Blends[1],
        image: "/BLENDS7.jpeg",
    },
    {
        id: Math.random(),
        name: "PV GADHWAL",
        category: "Blends",
        subcategory: categories.Blends[1],
        image: "/BLENDS8.jpeg",
    },
    {
        id: Math.random(),
        name: "PV SLUB",
        category: "Blends",
        subcategory: categories.Blends[1],
        image: "/BLENDS9.jpeg",
    },
    {
        id: Math.random(),
        name: "ROMMAN",
        category: "Blends",
        subcategory: categories.Blends[1],
        image: "/BLENDS10.jpeg",
    },
    {
        id: Math.random(),
        name: "ROMMAN",
        category: "Blends",
        subcategory: categories.Blends[1],
        image: "/BLENDS11.jpeg",
    },
    {
        id: Math.random(),
        name: "ROMMAN",
        category: "Blends",
        subcategory: categories.Blends[1],
        image: "/BLENDS12.jpeg",
    },
    {
        id: Math.random(),
        name: "Smooth Satin Weave",
        category: "Blends",
        subcategory: categories.Blends[1],
        image: "/BWSD8443.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND STRIP",
        category: "Blends",
        subcategory: categories.Blends[0],
        image: "/Blends2.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND PRINT",
        category: "Blends",
        subcategory: categories.Blends[0],
        image: "/Blends3.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND TWILL",
        category: "Blends",
        subcategory: categories.Blends[0],
        image: "/Blends4.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND TWILL",
        category: "Blends",
        subcategory: categories.Blends[0],
        image: "/Blends5.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND TWILL",
        category: "Blends",
        subcategory: categories.Blends[0],
        image: "/Blends6.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND SLUB",
        category: "Blends",
        subcategory: categories.Blends[4],
        image: "/BLENDS13.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LINEN STRIEP",
        category: "Blends",
        subcategory: categories.Blends[4],
        image: "/BLENDS15.jpeg",
    },
    {
        id: Math.random(),
        name: "PV MUSLIN",
        category: "Blends",
        subcategory: categories.Blends[4],
        image: "/BLENDS16.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HARINGBORN",
        category: "Blends",
        subcategory: categories.Blends[4],
        image: "/BLENDS17.jpeg",
    },
    {
        id: Math.random(),
        name: "POLYESTER",
        category: "Synthetic",
        subcategory: categories.Synthetic[0],
        image: "/SYNTH1.jpeg",
    },
    {
        id: Math.random(),
        name: "CXP LOTUS",
        category: "Synthetic",
        subcategory: categories.Synthetic[0],
        image: "/SYNTH2.jpg",
    },
    {
        id: Math.random(),
        name: "FIONA SATEEN",
        category: "Synthetic",
        subcategory: categories.Synthetic[0],
        image: "/SYNTH3.jpg",
    },
    {
        id: Math.random(),
        name: "HERCULES",
        category: "Synthetic",
        subcategory: categories.Synthetic[0],
        image: "/SYNTH4.jpg",
    },
    {
        id: Math.random(),
        name: "IRISH ORGANZA",
        category: "Synthetic",
        subcategory: categories.Synthetic[0],
        image: "/SYNTH5.jpg",
    },
    {
        id: Math.random(),
        name: "PXP BELUCHERRY",
        category: "Synthetic",
        subcategory: categories.Synthetic[0],
        image: "/SYNTH6.jpg",
    },
    {
        id: Math.random(),
        name: "RUI SILK",
        category: "Synthetic",
        subcategory: categories.Synthetic[0],
        image: "/SYNTH7.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND TWILL SLUB",
        category: "Blends",
        subcategory: categories.Blends[0],
        image: "/BLENDS18.jpeg",
    },
    {
        id: Math.random(),
        name: "BUBBLE",
        category: "Other",
        subcategory: categories.Other[0],
        image: "/BLENDS19.jpeg",
    },
    {
        id: Math.random(),
        name: "BUBBLE",
        category: "Other",
        subcategory: categories.Other[0],
        image: "/BUBBLE1.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND SANIL",
        category: "Blends",
        subcategory: categories.Blends[0],
        image: "/COTTON2.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HUMMER",
        category: "Blends",
        subcategory: categories.Blends[0],
        image: "/BLENDS21.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND JACQUARD",
        category: "Other",
        subcategory: categories.Other[3],
        image: "/JAQ1.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND JACQUARD",
        category: "Other",
        subcategory: categories.Other[3],
        image: "/JAQ2.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND JACQUARD",
        category: "Other",
        subcategory: categories.Other[3],
        image: "/JAQ3.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND JACQUARD",
        category: "Other",
        subcategory: categories.Other[3],
        image: "/JAQ4.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND JACQUARD",
        category: "Other",
        subcategory: categories.Other[3],
        image: "/JAQ5.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND JACQUARD",
        category: "Other",
        subcategory: categories.Other[3],
        image: "/JAQ6.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND JACQUARD",
        category: "Other",
        subcategory: categories.Other[3],
        image: "/JAQ7.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND JACQUARD",
        category: "Other",
        subcategory: categories.Other[3],
        image: "/JAQ8.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND JACQUARD",
        category: "Other",
        subcategory: categories.Other[3],
        image: "/JAQ9.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND NPOLY",
        category: "Other",
        subcategory: categories.Other[3],
        image: "/JAQ10.jpeg",
    },
    {
        id: Math.random(),
        name: "PATTERN",
        category: "Other",
        subcategory: categories.Other[4],
        image: "/PATT1.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HUMMER",
        category: "Other",
        subcategory: categories.Other[4],
        image: "/PATT2.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND GADHWAL",
        category: "Other",
        subcategory: categories.Other[4],
        image: "/PATT3.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BUTTA",
        category: "Other",
        subcategory: categories.Other[4],
        image: "/PATT4.jpeg",
    },
    {
        id: Math.random(),
        name: "PATTERN",
        category: "Other",
        subcategory: categories.Other[4],
        image: "/PATT5.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LIGHT SLUB",
        category: "Other",
        subcategory: categories.Other[4],
        image: "/PATT6.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND VATIKAN",
        category: "Other",
        subcategory: categories.Other[4],
        image: "/PATT7.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND NCV",
        category: "Other",
        subcategory: categories.Other[4],
        image: "/PATT8.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND",
        category: "Other",
        subcategory: categories.Other[4],
        image: "/PATT9.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HEMMER",
        category: "Other",
        subcategory: categories.Other[4],
        image: "/PATT10.jpeg",
    },
    {
        id: Math.random(),
        name: "PATTERN",
        category: "Other",
        subcategory: categories.Other[4],
        image: "/PATT11.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LILEN",
        category: "Natural",
        subcategory: categories.Natural[2],
        image: "/LI1.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LILEN",
        category: "Natural",
        subcategory: categories.Natural[2],
        image: "/LI2.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LILEN",
        category: "Natural",
        subcategory: categories.Natural[2],
        image: "/LI3.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LILEN STRIEP",
        category: "Natural",
        subcategory: categories.Natural[2],
        image: "/LI4.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BUTTA",
        category: "Natural",
        subcategory: categories.Natural[2],
        image: "/LI5.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BUTTA",
        category: "Natural",
        subcategory: categories.Natural[2],
        image: "/LI6.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LILEN",
        category: "Natural",
        subcategory: categories.Natural[2],
        image: "/LI7.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LILEN TWILL",
        category: "Natural",
        subcategory: categories.Natural[2],
        image: "/LI8.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LILEN",
        category: "Blends",
        subcategory: categories.Blends[3],
        image: "/LI1.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LILEN",
        category: "Blends",
        subcategory: categories.Blends[3],
        image: "/LI2.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LILEN",
        category: "Blends",
        subcategory: categories.Blends[3],
        image: "/LI3.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LILEN STRIEP",
        category: "Blends",
        subcategory: categories.Blends[3],
        image: "/LI4.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BUTTA",
        category: "Blends",
        subcategory: categories.Blends[3],
        image: "/LI5.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BUTTA",
        category: "Blends",
        subcategory: categories.Blends[3],
        image: "/LI6.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LILEN",
        category: "Blends",
        subcategory: categories.Blends[3],
        image: "/LI7.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LILEN TWILL",
        category: "Blends",
        subcategory: categories.Blends[3],
        image: "/LI8.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LYCRA",
        category: "Blends",
        subcategory: categories.Blends[5],
        image: "/LY1.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LYCRA",
        category: "Blends",
        subcategory: categories.Blends[5],
        image: "/LY2.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENT RINKLE",
        category: "Blends",
        subcategory: categories.Blends[0],
        image: "/RI1.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND JACQUARD",
        category: "Other",
        subcategory: categories.Other[3],
        image: "/JAQ11.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HEAVY",
        category: "Blends",
        subcategory: categories.Blends[0],
        image: "/BLENDS22.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND",
        category: "Blends",
        subcategory: categories.Blends[0],
        image: "/BLENDS23.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENT RINKLE",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/RI2.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENT",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/RI3.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND TWILL SLUB",
        category: "Blends",
        subcategory: categories.Blends[0],
        image: "/BLENDS24.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL1.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL1.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL1.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL2.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL3.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL4.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL5.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL6.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL7.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL8.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL9.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL10.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL11.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL12.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL13.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL14.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL15.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL16.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL17.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL18.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL19.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL20.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL21.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL22.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL23.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL24.jpeg",
    },
    {
        id: Math.random(),
        name: "VELVET",
        category: "Blends",
        subcategory: categories.Blends[2],
        image: "/VEL25.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BUTTI",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/VIS1.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND STRIP",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/Blends2.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND PRINT",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/Blends3.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND TWILL",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/Blends4.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND TWILL",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/Blends5.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND TWILL",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/Blends6.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND TWILL SLUB",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS18.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND SANIL",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/COTTON2.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HUMMER",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS21.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENT RINKLE",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/RI1.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HEAVY",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS22.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS23.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND TWILL SLUB",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS24.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND NEW",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS25.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND NEW STRIEP",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS26.jpeg",
    },
    {
        id: Math.random(),
        name: "PV MUSLIN",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS28.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENTING",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS29.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENTING",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS30.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HARINGBORN",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS31.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENT",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS32.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENT",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS33.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LILEN",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS34.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LILEN TWILL",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS35.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND TWILL",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS36.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HEAVY",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS38.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HEAVY",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS38.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HARINGBORN",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS39.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND TWILL SLUB",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS40.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BAMKOT",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS41.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BAMKOT",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS41.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT51.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT52.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT43.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT38.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT35.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BOX DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT27.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT28.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT29.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT30.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT31.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT7.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND Z DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT9.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT10.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT11.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT12.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT13.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND Z DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT14.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT16.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT17.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT18.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT19.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT22.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/DOBBY3.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/DOBBY4.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/DOBBY1.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/DOBBY2.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[2],
        image: "/TWIST1.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[2],
        image: "/TWIST2.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DOBBY",
        category: "Other",
        subcategory: categories.Other[2],
        image: "/TWIST3.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND PATTERN",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT1.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND VT PATTERN",
        category: "Natural",
        subcategory: categories.Natural[0],
        image: "/COTTON4.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND VT PATTERN",
        category: "Natural",
        subcategory: categories.Natural[1],
        image: "/BLENDS37.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND VT PATTERN",
        category: "Natural",
        subcategory: categories.Natural[0],
        image: "/COTTON4.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND ZARI PATTERN",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT55.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND I PATTERN",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT39.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND I PATTERN",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT40.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BOX PAT",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT2.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BOX PAT",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT3.jpeg",
    },
    {
        id: Math.random(),
        name: "SKY LAND COT",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT4.jpg",
    },
    {
        id: Math.random(),
        name: "PV VERTICLE",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT5.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND POLY SANIL",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT6.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND SLUB",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT8.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BOX PAT",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT20.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BOX",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT21.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND CHEX",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT23.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BOX",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT24.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BUTTI",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT25.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND Z BUTTI",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT26.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND NVM",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT15.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DANTING FLEX",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT32.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND JACQUARD",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT33.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BOX PAT",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT34.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LINE STRIEP",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT36.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BUTTA",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT37.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENTING",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT41.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BOX",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT42.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENT",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT44.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENT",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT45.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND DENT",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT46.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND POLYSTER",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT47.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT48.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LINEN",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT49.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LINEN TWILL",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT50.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND SATTIN BOX",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT53.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LUREX",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT54.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND RINKLE HEAVY",
        category: "Other",
        subcategory: categories.Other[1],
        image: "/PAT56.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND PRINT",
        category: "Other",
        subcategory: categories.Other[5],
        image: "/PAT57.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND PRINT",
        category: "Other",
        subcategory: categories.Other[5],
        image: "/PAT58.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND BUTTA",
        category: "Other",
        subcategory: categories.Other[5],
        image: "/PAT59.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND VATIKAN",
        category: "Other",
        subcategory: categories.Other[5],
        image: "/PAT60.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND GADWAL",
        category: "Other",
        subcategory: categories.Other[5],
        image: "/PAT61.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HUMMER",
        category: "Other",
        subcategory: categories.Other[5],
        image: "/PAT63.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND LIGHT SLUB",
        category: "Other",
        subcategory: categories.Other[5],
        image: "/PAT65.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND NCV",
        category: "Other",
        subcategory: categories.Other[5],
        image: "/PAT66.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND",
        category: "Other",
        subcategory: categories.Other[5],
        image: "/PAT67.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND HAMMER",
        category: "Other",
        subcategory: categories.Other[5],
        image: "/PAT68.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND",
        category: "Other",
        subcategory: categories.Other[5],
        image: "/PAT69.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND PV",
        category: "Other",
        subcategory: categories.Other[5],
        image: "/PAT70.jpg",
    },
    {
        id: Math.random(),
        name: "SKY LAND PV",
        category: "Other",
        subcategory: categories.Other[5],
        image: "/PAT71.jpg",
    },
];