import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from "../ui/button";

export default function InquiryForm() {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = (data) => {
        console.log('Form submitted:', data);
        reset();
    };

    return (
        <section className="px-4 sm:px-6 lg:px-8 bg-white mt-20">
            <div className="max-w-3xl mx-auto">
            {/* #C7A15E */}
                <h2 className="text-3xl font-medium lg:text-4xl mb-8 text-left text-[#f04b4c]">
                    Enquire Now
                </h2>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div>
                            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                                First Name
                            </label>
                            <input
                                type="text"
                                id="firstName"
                                {...register("firstName", { required: "First name is required" })}
                                className="w-full px-3 py-2 bg-white border border-gray-700"
                            />
                            {errors.firstName && <p className="text-red-500 text-sm">{errors.firstName.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                                Last Name
                            </label>
                            <input
                                type="text"
                                id="lastName"
                                {...register("lastName", { required: "Last name is required" })}
                                className="w-full px-3 py-2 bg-white border border-gray-700"
                            />
                            {errors.lastName && <p className="text-red-500 text-sm">{errors.lastName.message}</p>}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: "Invalid email address"
                                }
                            })}
                            className="w-full px-3 py-2 bg-white border border-gray-700"
                        />
                        {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
                    </div>
                    <div>
                        <label htmlFor="contactNumber" className="block text-sm font-medium text-gray-700 mb-1">
                            Contact Number
                        </label>
                        <input
                            type="tel"
                            id="contactNumber"
                            {...register("contactNumber", {
                                required: "Contact number is required",
                                pattern: {
                                    value: /^[0-9]+$/,
                                    message: "Invalid contact number"
                                }
                            })}
                            className="w-full px-3 py-2 bg-white border border-gray-700"
                        />
                        {errors.contactNumber && <p className="text-red-500 text-sm">{errors.contactNumber.message}</p>}
                    </div>
                    <div>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                            Message
                        </label>
                        <textarea
                            id="message"
                            {...register("message", { required: "Message is required" })}
                            rows="4"
                            className="w-full px-3 py-2 bg-white border border-gray-700"
                        ></textarea>
                        {errors.message && <p className="text-red-500 text-sm">{errors.message.message}</p>}
                    </div>
                    <div>
                        <label htmlFor="source" className="block text-sm font-medium text-gray-700 mb-1">
                            How did you get to know about us?
                        </label>
                        <select
                            id="source"
                            {...register("source", { required: "Please select a source" })}
                            className="w-full px-3 py-2 bg-white border border-gray-700"
                        >
                            <option value="">Select an option</option>
                            <option value="search">Search Engine</option>
                            <option value="social">Social Media</option>
                            <option value="referral">Referral</option>
                            <option value="advertisement">Advertisement</option>
                            <option value="other">Other</option>
                        </select>
                        {errors.source && <p className="text-red-500 text-sm">{errors.source.message}</p>}
                    </div>
                    <Button
                        variant="custom"
                        type="submit"
                        className='text-white bg-black'
                    >
                        Submit Inquiry
                    </Button>
                </form>
            </div>
        </section>
    );
}