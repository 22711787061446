import * as React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Card, CardContent } from "../ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "../ui/carousel";
import { Button } from "../ui/button";
import Autoplay from "embla-carousel-autoplay";

const milestones = [
  {
    year: 2014,
    title: "Founding Year",
    description: "Frontline Fabrics was established with a vision to revolutionize the textile industry.",
  },
  {
    year: 2015,
    title: "First Major Client",
    description: "Secured our first major client, marking the beginning of our growth journey.",
  },
  {
    year: 2016,
    title: "Eco-Friendly Initiative",
    description: "Launched our first line of eco-friendly fabrics, setting a new industry standard.",
  },
  {
    year: 2017,
    title: "International Expansion",
    description: "Expanded operations to three new countries, broadening our global footprint.",
  },
  {
    year: 2018,
    title: "Innovation Award",
    description: "Received the Textile Innovation of the Year award for our smart fabric technology.",
  },
  {
    year: 2019,
    title: "Sustainability Milestone",
    description: "Achieved 50% reduction in water usage across all production processes.",
  },
  {
    year: 2020,
    title: "Digital Transformation",
    description: "Implemented AI-driven quality control, enhancing product consistency.",
  },
  {
    year: 2021,
    title: "Circular Economy Initiative",
    description: "Launched a fabric recycling program, promoting sustainable practices.",
  },
  {
    year: 2022,
    title: "Fashion Week Debut",
    description: "Our fabrics featured in major fashion weeks across the globe.",
  },
  {
    year: 2023,
    title: "R&D Breakthrough",
    description: "Patented a new fabric treatment process, revolutionizing durability.",
  },
  {
    year: 2024,
    title: "10th Anniversary",
    description: "Celebrating a decade of innovation, sustainability, and quality in textiles.",
  },
];

export default function JourneySection() {
  const [api, setApi] = React.useState(null);
  const [current, setCurrent] = React.useState(0);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <section className="mt-20 px-4 sm:px-6 lg:px-8 bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto">
        <div className="lg:flex justify-between items-center lg:mb-12">
          <div className="text-left">
          {/* #C7A15E */}
            <h2 className="text-3xl font-[500] lg:text-4xl" style={{ color: "#f04b4c" }}>
              Our Journey
            </h2>
            <p className="mt-3 max-w-2xl lg:text-xl text-gray-600 sm:mt-4">
              A Timeline of Milestones
            </p>
          </div>
          <div className="flex space-x-2 pb-2 mt-8 lg:mt-0">
            <Button
              variant="outline"
              size="icon"
              className="w-10 h-10 rounded-full"
              onClick={() => api?.scrollPrev()}
            >
              <ChevronLeft className="h-6 w-6" />
              <span className="sr-only">Previous year</span>
            </Button>
            <Button
              variant="outline"
              size="icon"
              className="w-10 h-10 rounded-full"
              onClick={() => api?.scrollNext()}
            >
              <ChevronRight className="h-6 w-6" />
              <span className="sr-only">Next year</span>
            </Button>
          </div>
        </div>
        <Carousel
          setApi={setApi}
          className="w-full"
          plugins={[
            Autoplay({
              delay: 5000,
            }),
          ]}
          opts={{
            align: "start",
            loop: false,
          }}
        >
          <CarouselContent className="-ml-2 md:-ml-4">
            {milestones.map((milestone) => (
              <CarouselItem key={milestone.year} className="pl-2 md:pl-4 md:basis-1/2 lg:basis-[28%]">
                <Card className="border-0 bg-white shadow-lg h-full">
                  <CardContent className="flex flex-col justify-between p-6 h-full">
                    <div>
                    {/* #C7A15E */}
                      <h3 className="text-2xl font-semibold mb-2" style={{ color: "#f04b4c" }}>
                        {milestone.year}
                      </h3>
                      <h4 className="text-xl font-semibold mb-2">{milestone.title}</h4>
                      <p className="text-gray-600">{milestone.description}</p>
                    </div>
                  </CardContent>
                </Card>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
        <div className="mt-4">
          {/* <div className="w-full bg-gray-200 rounded-full h-2.5 mb-2">
            <div 
              className="bg-[#000]/60 h-2.5 rounded-full transition-all duration-300 ease-in-out" 
              style={{ width: `${(current / count) * 100}%` }}
            ></div>
          </div> */}
          <div className="text-center text-sm text-gray-600">
            {milestones[current - 1]?.year} - Year {current} of {count}
          </div>
        </div>
      </div>
    </section>
  );
};