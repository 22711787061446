import { Mail, MessageSquareMore } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const navigateToEmail = () => {
    window.location.href = "mailto:info@frontlinetextiles.com";
};

export const HeadLine = () => {
    const navigate = useNavigate();

    return (
        <div className="h-[40px] md:h-[62px] flex items-center z-50 border-b border-[#f04b4c]/10">
            <div className="container flex justify-center md:justify-between items-center text-[18px]">
                <div onClick={navigateToEmail} className="flex items-center gap-x-2 cursor-pointer">
                    <Mail size={18} className="text-[#000]" />
                    <div className="alt-serif">info@frontlinetextiles.com</div>
                </div>
                <div onClick={() => navigate("/")} className="hidden md:flex">
                    <img src="/logo.png" className="alt-serif4 h-[180px] cursor-pointer" alt="logo" />
                </div>
                <div className="hidden md:flex items-center gap-x-2">
                    <MessageSquareMore size={18} className="text-[#000]" />
                    (+91) 78782 51565
                </div>
            </div>
        </div>
    );
};