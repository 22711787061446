import * as React from "react";
import { Star, ChevronLeft, ChevronRight } from "lucide-react";
import { Card, CardContent } from "../ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "../ui/carousel";
import { Button } from "../ui/button";

const reviews = [
  {
    id: 1,
    name: "Dineshbhai Prajapati",
    company: "Fashion Forward Co.",
    review: "Frontline's fabric quality is unmatched. Their attention to detail and innovative designs have significantly elevated our clothing line.",
    rating: 5,
  },
  {
    id: 2,
    name: "Chetanbhai Mangukiya",
    company: "Eco Textiles",
    review: "Working with Frontline has been a game-changer for our sustainable fashion brand. Their eco-friendly fabrics are both beautiful and durable.",
    rating: 5,
  },
  {
    id: 3,
    name: "Hareshbhai Tejani",
    company: "Luxe Textiles",
    review: "The variety and quality of upholstery fabrics from Frontline have transformed our interior design projects. Highly recommended!",
    rating: 5,
  },
  {
    id: 4,
    name: "Manharbhai Borad",
    company: "Maruti Enterprise",
    review: "Frontline's performance fabrics have revolutionized our outdoor clothing line. Durable, weather-resistant, and stylish!",
    rating: 5,
  },
];

export default function ClientReviewsCarousel() {
  const [api, setApi] = React.useState(null);
  const [current, setCurrent] = React.useState(0);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <section className="mt-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-[500] lg:text-4xl">
            What Our <span className="text-[#f04b4c]">Clients</span> Say
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-600 sm:mt-4">
            Discover why leading brands trust Frontline for their fabric needs
          </p>
        </div>
        <Carousel setApi={setApi} className="w-full">
          <CarouselContent>
            {reviews.map((review) => (
              <CarouselItem key={review.id}>
                <Card className="border-0" style={{ borderColor: "#C7A15E" }}>
                  <CardContent className="flex flex-col items-start p-6">
                    <div className="flex items-center mb-4">
                      {/* <div className="flex-shrink-0">
                        <img
                          className="h-12 w-12 rounded-full object-cover"
                          src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhObwH3IBSJGPl5qBCWnsFJDwtF2ABk09n-w&s`}
                          alt={review.name}
                        />
                      </div> */}
                      <div className="ml-0">
                      {/* #C7A15E */}
                        <h3 className="text-lg font-medium" style={{ color: "#f04b4c" }}>
                          {review.name}
                        </h3>
                        <p className="text-sm text-gray-500">{review.company}</p>
                      </div>
                    </div>
                    <p className="text-gray-600 mb-4">{review.review}</p>
                    <div className="flex items-center">
                      {[...Array(5)].map((_, i) => (
                        <Star
                          key={i}
                          className={`h-5 w-5 ${
                            i < review.rating ? "text-yellow-400" : "text-gray-300"
                          }`}
                          fill="currentColor"
                        />
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="flex justify-center mt-4 space-x-2">
            <Button
              variant="outline"
              size="icon"
              className="w-10 h-10 rounded-full"
              onClick={() => api?.scrollPrev()}
            >
              <ChevronLeft className="h-6 w-6" />
              <span className="sr-only">Previous review</span>
            </Button>
            <Button
              variant="outline"
              size="icon"
              className="w-10 h-10 rounded-full"
              onClick={() => api?.scrollNext()}
            >
              <ChevronRight className="h-6 w-6" />
              <span className="sr-only">Next review</span>
            </Button>
          </div>
          <div className="py-2 text-center text-sm text-muted-foreground">
            Review {current} of {count}
          </div>
        </Carousel>
      </div>
    </section>
  );
};