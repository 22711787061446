import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const products = [
  {
    name: "Cotton",
    image: "/Cotton.jpeg",
    href: "/collection?subcategory=Cotton",
  },
  {
    name: "Viscose",
    image:
      "https://media-hosting.imagekit.io//819a380b590b4931/f2.JPG?Expires=1833882269&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=15McVCCuaOa1030wleRbu1zN84VtiUVohmc~reLWsbsCzRqon0zbknjy~HR5zltjuMfjr8~cmSLacqeh~Jpfscig1ky743D3K04OGrKD2cR~~v2AkZDRtyoSN4avtPAByMGvpmv9CgHydBl4K2VO5aBUjpbwBXZMM8DZPx4ohnjMvN9J1aYFGWPW07sCDr14wt-jvDXtyfiI7ZwHD6HlfTYiBWnSSsuU0OnDyE6rTi9Lc56G13pQZgAg9~9E~H4v2snwVUE2an8PcIGYHKzODhMsIkh8APomda9SjJp3nUvUZP6RYmvHIeLmPaOzNy~0hROacN21obGZrBbv31lswQ__",
    href: "/collection?subcategory=Viscose",
  },
  {
    name: "Linen",
    image: "/Linen.jpeg",
    href: "/collection?subcategory=Linen",
  },
  {
    name: "Nylon X  Viscos",
    image: "/COTTON2.jpeg",
    href: "/collection?subcategory=N x V",
  },
  {
    name: "Lycra",
    image: "Lycra.jpeg",
    href: "/collection?subcategory=Viscose Lycra",
  },
  {
    name: "Dobby",
    image: "dobbynew.jpeg",
    href: "/collection?subcategory=Dobby",
  },
  {
    name: "Jacquard",
    image: "/jaqnew.jpeg",
    href: "/collection?subcategory=Jacquard",
  },
  {
    name: "Satin",
    image: "/f10.jpeg",
    href: "/collection?subcategory=Pattern",
  },
];

export const ProductSection = () => {
  const navigate = useNavigate();

  const fadeIn = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const staggerContainer = {
    visible: {
      transition: {
        staggerChildren: 0.3, // Delay between elements
      },
    },
  };

  const handleNavigation = (href) => {
    navigate(href);
  };

  return (
    <div className="container">
      <section className="bg-white py-12 lg:px-6 md:px-12">
        <div className="mx-auto flex flex-col md:flex-row gap-4">
          {/* Left Content */}
          {/* <div className="md:w-[33%]">
            <h2 className="text-blue-600 font-semibold text-sm">Our Products</h2>
            <h1 className="text-3xl font-semibold text-orange-600 mt-2">
              Premium-Quality Textiles Crafted by Frontline
            </h1>
            <p className="text-gray-600 mt-4">
              Frontline specializes in premium-quality textiles, offering an
              extensive selection of yarns and woven fabrics. Each product is
              meticulously crafted with precision and care to ensure superior
              quality. Explore our collection to find the perfect textile
              solutions tailored to your needs.
            </p>
          </div> */}

          <motion.div
            className="md:w-[33%]"
            initial="hidden"
            whileInView="visible"
            variants={staggerContainer}
            viewport={{ once: false, amount: 0.3 }} // Ensures animation runs multiple times
          >
            <motion.h2
              className="text-blue-600 font-semibold text-sm"
              variants={textVariants}
            >
              Our Products
            </motion.h2>

            <motion.h1
              className="text-3xl font-semibold text-[#f04b4c] mt-2"
              variants={textVariants}
            >
              Premium-Quality Textiles Crafted by Frontline
            </motion.h1>

            <motion.p className="text-gray-600 mt-4" variants={textVariants}>
              Frontline specializes in premium-quality textiles, offering an
              extensive selection of yarns and woven fabrics. Each product is
              meticulously crafted with precision and care to ensure superior
              quality. Explore our collection to find the perfect textile
              solutions tailored to your needs.
            </motion.p>
          </motion.div>

          {/* Right Side Top Two Large Items */}
          {/* <div className="md:w-[67%]">
                        <div className="grid lg:grid-cols-2 gap-4 mb-4">
                            {products.slice(0, 2).map((product, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleNavigation(product.href)}
                                    className="bg-white border border-gray-300 flex flex-col items-center justify-center min-h-[180px] text-center cursor-pointer"
                                >
                                    <img
                                        src={product.image}
                                        alt={product.name}
                                        className="h-[250px] w-full object-cover"
                                    />
                                    <h3 className="text-lg font-semibold py-2">{product.name}</h3>
                                </div>
                            ))}
                        </div>
                    </div> */}
          <div className="md:w-[67%]">
            <motion.div
              className="grid lg:grid-cols-2 gap-4 mb-4"
              initial="hidden"
              whileInView="visible"
              variants={fadeIn}
              viewport={{ once: false, amount: 0.2 }} // Triggers multiple times
            >
              {products.slice(0, 2).map((product, index) => (
                <motion.div
                  key={index}
                  onClick={() => handleNavigation(product.href)}
                  className="bg-white border border-gray-300 flex flex-col items-center justify-center min-h-[180px] text-center cursor-pointer"
                  variants={fadeIn}
                >
                  <img
                    src={product.image}
                    alt={product.name}
                    className="h-[250px] w-full object-cover"
                  />
                  <h3 className="text-lg font-semibold py-2">{product.name}</h3>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </div>

        {/* Bottom Grid with 6 Items Below Content and Two Large Images */}
        {/* <div className="mx-auto grid lg:grid-cols-3 gap-4 mt-4">
          {products.slice(2).map((product, index) => (
            <div
              key={index}
              onClick={() => handleNavigation(product.href)}
              className="bg-white border border-gray-300 flex flex-col items-center justify-center min-h-[180px] text-center cursor-pointer"
            >
              <img
                src={product.image}
                alt={product.name}
                className="h-[250px] w-full object-cover"
              />
              <h3 className="text-lg font-semibold py-2">{product.name}</h3>
            </div>
          ))}
        </div> */}
        <motion.div
          className="mx-auto grid lg:grid-cols-3 gap-4 mt-4"
          initial="hidden"
          whileInView="visible"
          variants={fadeIn}
          viewport={{ once: false, amount: 0.2 }} // Ensures animation on each scroll
        >
          {products.slice(2).map((product, index) => (
            <motion.div
              key={index}
              onClick={() => handleNavigation(product.href)}
              className="bg-white border border-gray-300 flex flex-col items-center justify-center min-h-[180px] text-center cursor-pointer"
              variants={fadeIn}
            >
              <img
                src={product.image}
                alt={product.name}
                className="h-[250px] w-full object-cover"
              />
              <h3 className="text-lg font-semibold py-2">{product.name}</h3>
            </motion.div>
          ))}
        </motion.div>
      </section>
    </div>
  );
};

export default ProductSection;
