import React, { useEffect, useRef } from 'react';

const PartnerLogos = () => {
    const logos = [
        { src: "https://thecapitalmall.com/wp-content/uploads/2023/10/Trends-Capital-Mall.png" },
        { src: "https://www.westside.com/cdn/shop/files/w-logo.png?height=628&pad_color=fff&v=1687335574&width=1200" },
        { src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTMtAudxwvCotWaKK4WPVGxP3Jmol4opSFsw&s" },
        { src: "https://logomakerr.ai/blog/wp-content/uploads/2022/08/2019-to-Present-Zara-logo-design-1024x538.jpg" },
        { src: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Walmart_logo.svg/1200px-Walmart_logo.svg.png" },
        { src: "https://www.lucknow.lulumall.in/wp-content/uploads/2024/05/zudio-logo.png" },
        { src: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Logo_of_Max_Fashion_and_Accessories%2C_March_2018.png" },
        { src: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/H%26M-Logo.svg/1200px-H%26M-Logo.svg.png" },
    ];

    const scrollerRef = useRef(null);

    useEffect(() => {
        const scrollerElement = scrollerRef.current;
        if (!scrollerElement) return;

        const scrollerInner = scrollerElement.querySelector('.scroller-inner');

        // Clone items multiple times for seamless looping
        const scrollerContent = Array.from(scrollerInner.children);
        for (let i = 0; i < 2; i++) {
            scrollerContent.forEach(item => {
                const clonedItem = item.cloneNode(true);
                scrollerInner.appendChild(clonedItem);
            });
        }
    }, []);

    return (
        <section className="w-full py-12 bg-white">
            <div className="mx-auto px-4">
                <div className="text-center mb-12">
                    <h2 className="text-3xl md:text-4xl font-semibold mb-4">Our Valued <span className='text-[#f04b4c]'> Partners</span></h2>
                    <p className="text-lg text-gray-600 max-w-3xl mx-auto">
                        Thank you for your ongoing support and collaboration. Together, we continue to innovate and deliver
                        high-quality products to our customers.
                    </p>
                </div>

                <div ref={scrollerRef} className="relative overflow-hidden">
                    <div
                        className="scroller-inner flex items-center"
                        style={{
                            animation: 'scroll 30s linear infinite',
                        }}
                    >
                        {logos.map((logo, index) => (
                            <div
                                key={index}
                                className="flex-shrink-0 mx-8 flex items-center justify-center w-28 h-20 sm:w-36 sm:h-28"
                            >
                                <img
                                    src={logo.src || "/placeholder.svg"}
                                    alt={`Partner ${index + 1}`}
                                    className="max-w-full max-h-full object-contain"
                                />
                            </div>
                        ))}
                    </div>

                    <style jsx>{`
                        @keyframes scroll {
                            from {
                                transform: translateX(0);
                            }
                            to {
                                transform: translateX(-100%);
                            }
                        }

                        .scroller-inner {
                            display: flex;
                            white-space: nowrap;
                        }

                        .scroller-inner:hover {
                            animation-play-state: paused;
                        }
                    `}</style>
                </div>
            </div>
        </section>
    );
};

export default PartnerLogos;
