  import { useState, useRef, useEffect, useCallback } from 'react';
import { ChevronDown } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import { categories } from '../lists/product-list';

export const CollectionDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const CLOSE_DELAY = 300;
  let closeTimeout;

  const handleMouseEnter = useCallback(() => {
    clearTimeout(closeTimeout);
    setIsOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    closeTimeout = setTimeout(() => {
      setIsOpen(false);
    }, CLOSE_DELAY);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div
      className="relative group"
      ref={dropdownRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`flex items-center cursor-pointer font-[500] text-base ${pathname.startsWith('/collection') ? 'text-[#f04b4c]' : 'hover:text-[#f04b4c]'}`}
        onClick={() => navigate("/collection")}
      >
        Collection
        <ChevronDown size={16} className="ml-1" />
      </div>
      <div className="absolute h-4 w-full" />
      {isOpen && (
        <div
          className="absolute top-[calc(100%+16px)] left-1/2 transform -translate-x-1/2 p-6 bg-white shadow-lg flex items-center justify-center rounded-lg w-[800px] z-50"
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className='grid grid-cols-4 gap-24'
          >
            {categories &&
              Object.keys(categories).map((column, index) => (
                <div key={index}>
                  <h3 className="font-semibold text-lg mb-2">{column}</h3>
                  <ul>
                    {
                      categories[column].map((subcategory) => (
                        <li
                          key={subcategory}
                          className="py-1 hover:text-[#f04b4c] cursor-pointer"
                          onClick={() => {
                            navigate(`/collection?subcategory=${subcategory}`);
                            setIsOpen(false);
                          }}
                        >
                          {subcategory}
                        </li>
                      ))
                    }
                  </ul>
                </div>
              ))}
          </motion.div>
        </div>
      )}
    </div>
  );
};