'use client'

import * as React from "react"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "../ui/carousel"
import { Card, CardContent } from "../ui/card"

const headquartersImages = [
  { src: "https://drapersitaly.it/public/uploads/drapersmedia-zcsf-20240430-slider-sede-1.jpg", alt: "Headquarters 1" },
  { src: "https://drapersitaly.it/public/uploads/drapersmedia-gkhm-20240430-slider-sede-2.jpg", alt: "Headquarters 2" },
  { src: "https://drapersitaly.it/public/uploads/drapersmedia-arbj-20240430-slider-sede-3.jpg", alt: "Headquarters 3" },
  // { src: "/office1.jpeg", alt: "Headquarters 3" },

]

export function HeadquartersGallery() {
  return (
    <div className="w-full pt-20">
      <h2 className="text-3xl mb-8 text-center">Our <spanc className="text-[#f04b4c]">Headquarters</spanc></h2>
      <Carousel
        opts={{
          align: "start",
          loop: false,
        }}
        className="w-full"
      >
        <CarouselContent>
          {headquartersImages.map((image, index) => (
            <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/25 xl:basis-[700px]">
              <div className="p-1">
                <Card>
                  <CardContent className="flex items-center justify-center p-0">
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="w-full object-cover h-[500px]"
                    />
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  )
}