import React, { useState, useEffect } from 'react';
import './App.css';
import { Footer } from './components/footer';
import { HeadLine } from './components/headline';
import { Navbar } from './components/navbar';
import Home from './pages/home';
import { ArrowUp } from 'lucide-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import About from './pages/about';
import Services from './pages/services';
import Contact from './pages/contact';
import Catalog from './pages/catalog';
import Sustainability from './pages/sustainability';
import Quality from './pages/quality';
import Fivesaudit from './pages/fivesaudit';
import FabricCategoryPage from './pages/new-catalog';

function App() {

  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <BrowserRouter>
      {showScrollToTop && (
        <motion.button
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0, opacity: 0 }}
          transition={{ duration: 0.1 }}
          onClick={scrollToTop}
          className="fixed bottom-10 right-5 h-[40px] w-[40px] p-0 bg-black text-white rounded-[0px] flex justify-center items-center cursor-pointer scrolltotop"
        >
          <ArrowUp />
        </motion.button>
      )}
      <div className='sticky top-0 z-50 shadow-lg shadow-black/5 bg-white/95 backdrop-blur-md'>
        <HeadLine />
        <Navbar />
      </div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/company' element={<About />} />
        {/* <Route path='/service' element={<Services />} /> */}
        <Route path='/collection' element={<FabricCategoryPage />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/sustainability' element={<Sustainability />} />
        <Route path='/quality' element={<Quality />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;