import React from "react";
import { motion } from "framer-motion";

const SustainabilitySection = () => {
  // Animation Variants
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
  };

  return (
    <div className="relative bg-[#E6F4E6] h-[1200px] lg:h-[1200px] p-[5rem] lg:p-[10rem] px-6 md:px-20 mt-20">
      {/* Background Container */}
      <div className="absolute inset-0">
        <img
          src="https://cdn.prod.website-files.com/6458e835fee2dd2a405bf1de/6458e835fee2dde7a95bf351_bg-sustainability%20(2).png"
          alt="Sustainability Background"
          className="w-full h-[300px] lg:h-[1000px] object-cover lg:object-fill absolute bottom-0 opacity-80"
        />
      </div>

      {/* Content Container */}
      <motion.div
        className="relative z-10 flex flex-col md:flex-row items-center md:items-start"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
      >
        {/* Left Side - Heading */}
        <motion.div
          className="text-center md:text-left md:w-1/2"
          variants={itemVariants}
        >
          <h2 className="text-3xl font-semibold text-gray-900">Sustainability since</h2>
          <p className="text-7xl font-semibold text-green-800 mt-4">2014</p>
        </motion.div>

        {/* Right Side - Information */}
        <motion.div
          className="md:w-1/2 space-y-10 mt-10 md:mt-0"
          variants={containerVariants}
        >
          {[
            {
              icon: "https://cdn.prod.website-files.com/6458e835fee2dd2a405bf1de/6458e835fee2dd6fff5bf2ff_Icon%20(7).svg",
              title: "Maximizing Energy Efficiency with Energy-Efficient Machinery",
              description:
                "Frontline keeps redefining textiles sustainably. Our energy-efficient lighting and cutting-edge equipment maximize efficiency, reducing consumption for a greener future.",
            },
            {
              icon: "https://cdn.prod.website-files.com/6458e835fee2dd2a405bf1de/6458e835fee2ddb8125bf301_Icon%20(8).svg",
              title: "Leading Sustainability with 85% Renewable Energy",
              description:
                "Our manufacturing system is powered by 85% renewable energy sources, including wind and solar power. This ensures that our operations are not dependent on non-renewable resources and reduces our carbon footprint.",
            },
            {
              icon: "https://cdn.prod.website-files.com/6458e835fee2dd2a405bf1de/6458e835fee2dd01ae5bf2fe_Icon%20(9).svg",
              title: "Building a Circular Economy with Sustainable Energy",
              description:
                "Frontline utilizes sustainable energy practices to reduce waste and create a circular economy that benefits the environment and our company.",
            },
          ].map((item, index) => (
            <motion.div key={index} className="flex items-start" variants={itemVariants}>
              <img src={item.icon} alt={item.title} className="w-10 h-10 mr-4" />
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default SustainabilitySection;


